import * as React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import SimCardRoundedIcon from '@mui/icons-material/SimCardRounded';

import { styled } from '@mui/system';
import { InputLabel, ListItem, MenuItem, Select } from '@mui/material';

const FormGrid = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

interface IOnChangeData {
  cardToken: string;
  cvv: string;
  holderName: string;
  expirationDate: string;
  isFormValid: boolean;
  installments: string
}

interface IProps {
  amount: number;
  onChangeData: (data: IOnChangeData) => void;
}

export default function PaymentForm({ amount, onChangeData }: IProps) {
  const [paymentType, setPaymentType] = React.useState('creditCard');
  const [cardNumber, setCardNumber] = React.useState('');
  const [cvv, setCvv] = React.useState('');
  const [holderName, setHolderName] = React.useState('');
  const [expirationDate, setExpirationDate] = React.useState('');
  const cardTokenRef = React.useRef<HTMLInputElement>(null);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [installments, setInstallments] = React.useState("");

  React.useEffect(() => {
    onChangeData({
      cardToken: cardTokenRef.current?.value ?? '',
      cvv,
      holderName,
      expirationDate,
      isFormValid,
      installments
    });
  }, [
    cardTokenRef.current?.value,
    cvv,
    holderName,
    expirationDate,
    isFormValid,
    installments
  ]);

  React.useEffect(() => {
    if (cardTokenRef.current) {
      cardTokenRef.current.value = '';
    }
  }, [cardNumber]);

  const handleCardNumberChange = (event: { target: { value: string } }) => {
    const value = event.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1 ');
    if (value.length <= 16) {
      setCardNumber(formattedValue);
    }
  };

  const handleNameChange = (event: { target: { value: string } }) => {
    const value = event.target.value;
    setHolderName(value);
  };

  const handleCvvChange = (event: { target: { value: string } }) => {
    const value = event.target.value.replace(/\D/g, '');
    if (value.length <= 3) {
      setCvv(value);
    }
  };

  const handleExpirationDateChange = (event: { target: { value: string } }) => {
    const value = event.target.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{2})(?=\d{2})/, '$1/');
    if (value.length <= 4) {
      setExpirationDate(formattedValue);
    }
  };

  React.useEffect(() => {
    const isValid =
      cardNumber.length === 19 &&
      cvv.length === 3 &&
      holderName.trim() !== '' &&
      expirationDate.length === 5;
    installments !== "0"

    setIsFormValid(isValid);
  }, [cardNumber, cvv, holderName, expirationDate, installments]);

  return (
    <Stack spacing={{ xs: 3, sm: 6 }}>
      <FormControl component="fieldset" fullWidth></FormControl>
      {paymentType === 'creditCard' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 3,
              height: { xs: 420, sm: 420, md: 420 },
              gap: 2,
              width: '100%',
              borderRadius: '20px',
              border: '1px solid ',
              borderColor: 'divider',
              backgroundColor: 'background.paper',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle2">Cartão de credito</Typography>
              <CreditCardRoundedIcon sx={{ color: 'text.secondary' }} />
            </Box>
            <SimCardRoundedIcon
              sx={{
                fontSize: { xs: 48, sm: 56 },
                transform: 'rotate(90deg)',
                color: 'text.secondary',
              }}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-name" required>
                  Nome
                </FormLabel>
                <OutlinedInput
                  id="card-name"
                  autoComplete="card-name"
                  placeholder="John Smith"
                  required
                  value={holderName}
                  onChange={handleNameChange}
                />
              </FormGrid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: 2,
              }}
            >
              <FormGrid sx={{ flexGrow: 1 }}>
                <FormLabel htmlFor="card-number" required>
                  Numero do cartão
                </FormLabel>
                <OutlinedInput
                  inputComponent="input"
                  inputProps={{ className: 'pjbank-cartao' }}
                  id="card-number"
                  className="pjbank-cartao"
                  autoComplete="card-number"
                  placeholder="0000 0000 0000 0000"
                  required
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                />
                <input
                  type="hidden"
                  id="card-token"
                  name="card-token"
                  className="pjbank-token"
                  ref={cardTokenRef}
                />
              </FormGrid>
              <FormGrid sx={{ maxWidth: '15%' }}>
                <FormLabel htmlFor="card-expiration" required>
                  Mes/Ano
                </FormLabel>
                <OutlinedInput
                  id="card-expiration"
                  autoComplete="card-expiration"
                  placeholder="MM/YY"
                  required
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                />
              </FormGrid>
              <FormGrid sx={{ display: 'flex', maxWidth: '10%' }}>
                <FormLabel htmlFor="cvv" required>
                  CVV
                </FormLabel>
                <OutlinedInput
                  id="cvv"
                  autoComplete="CVV"
                  placeholder="123"
                  required
                  value={cvv}
                  onChange={handleCvvChange}
                />
              </FormGrid>
            </Box>
            <Box sx={{ flexGrow: 1, gap: 2 }}>
              <FormGrid>
                <FormLabel htmlFor='parcelas-dropdown'>
                  Parcelas
                </FormLabel>


                {/* O InputLabel funciona como o rótulo do Select */}
                <InputLabel
                  sx={{
                    left: '2%',
                    top: '50%', // Centraliza o label verticalmente
                    transform: 'translateY(-50%)', // Ajusta o label para o centro
                  }}
                  id="parcelas-label"
                  htmlFor='parcelas-dropdown'
                >
                  {/* Selecione a quantidade */}
                </InputLabel>

                <Select
                  labelId="parcelas-label" // Conecta o InputLabel ao Select
                  id="parcelas-dropdown"
                  value={installments}
                  displayEmpty
                  // aria-selected='Selecione a quantidade de parcelas'
                  onChange={(e) => {
                    setInstallments(e.target.value);
                  }}
                  renderValue={(selected) => {
                    // Se não houver um valor selecionado, exibe o placeholder
                    if (!selected) {
                      return <p>Selecione a quantidade desejada de parcelas</p>;
                    }
                    // Se houver valor, exibe o valor normalmente
                    return `${selected}x parcelas R$ ${Number(amount / Number(selected)).toFixed(2)} (R$ ${amount})`;
                  }}
                >

                  {[1, 2, 3].map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItem>{option}x parcelas R$ {Number(amount / option).toFixed(2)} (R$ {amount})</ListItem>
                    </MenuItem>
                  ))}
                </Select>
              </FormGrid>
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );
}
