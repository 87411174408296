import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
	Icon,
	IconButton,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../../context";
import { useProfile } from "../../../context/ProfileContext";
import { BasicModal, Toolbar, ToolbarGridSinister } from "../../../shared/components";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { Environment } from "../../../shared/environment";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useDebounce } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { ISinisterList, SinisterService } from "../../../shared/services/api/sinister/SinisterService";
import { IDetalheUsuario } from "../../../shared/services/api/user/userService";
import { TFilterFields } from "../../../shared/types/filterfields.type";
import { globalTranslate } from "../../../shared/utils";
import { ListSearchParams } from "../../analysis/types/list-search-params.type";

//open,in_progress,debts_confession,finalized,monthly_compensation,lmi_exhaustion,pending,canceled,sketch
function colorBackGround(status: string) {
	if (status === "open") {
		return "#ebcea9";
	}
	//if (status === 'created') { return '#ebcea9' }
	if (status === "in_progress") {
		return "#a9e9eb";
	}
	if (status === "finalized") {
		return "#C5EEC9";
	}
	if (status === "pending") {
		return "#F4ECC0";
	}
	if (status === "canceled") {
		return "#EEC5C5";
	}
	if (status === "debts_confession") {
		return "#C0CC2C";
	}
	if (status === "monthly_compensation") {
		return "#07E98C";
	}
	if (status === "lmi_exhaustion") {
		return "#ED6002";
	}
	if (status === "sketch") {
		return "#46D9FE";
	}
	return "#959595";
}
function colorIcon(status: string) {
	if (status === "open") {
		return "#e4870f";
	}
	if (status === "in_progress") {
		return "#12a6ac";
	}
	if (status === "finalized") {
		return "#12c224";
	}
	if (status === "pending") {
		return "#c4a80d";
	}
	if (status === "canceled") {
		return "#eb3232";
	}
	if (status === "debts_confession") {
		return "#6c7507";
	}
	if (status === "monthly_compensation") {
		return "#079157";
	}
	if (status === "lmi_exhaustion") {
		return "#813b0c";
	}
	if (status === "sketch") {
		return "#25697a";
	}

	return "#202020";
}

function colorText(status: string) {
	if (status === "open") {
		return "#e4870f";
	}
	if (status === "in_progress") {
		return "#12a6ac";
	}
	if (status === "finalized") {
		return "#12c224";
	}
	if (status === "pending") {
		return "#c4a80d";
	}
	if (status === "canceled") {
		return "#eb3232";
	}
	if (status === "debts_confession") {
		return "#6c7507";
	}
	if (status === "monthly_compensation") {
		return "#079157";
	}
	if (status === "lmi_exhaustion") {
		return "#813b0c";
	}
	if (status === "sketch") {
		return "#25697a";
	}

	return "#202020";
}

function translate(traduzir: String) {
	if (traduzir === "in_progress") {
		return "Em Andamento";
	}
	if (traduzir === "created") {
		return "Aberto";
	}
	if (traduzir === "finalized") {
		return "Finalizado";
	}
	if (traduzir === "pending") {
		return "Pendente";
	}
	if (traduzir === "canceled") {
		return "Cancelado";
	}

	return traduzir;
}

export const SinisterListPresentation = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const [rows, setRows] = useState<ISinisterList[]>([]);
	const [rowUser, setRowUser] = useState<IDetalheUsuario>();
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [openModalCheckList, setOpenModalCheckList] = useState(false);
	const [propertyOccupied, setPropertyOccupied] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [pesquisar, setPesquisar] = useState(true);
	const { loggedUser } = useAuthContext();
	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.View);
	}, []);
	const { isSuperAdmin, permissionLevel } = useProfile();

	const optionfilterPeriod: TFilterFields[] = [
		{ name: "criacao", displayName: "Criação" },
		{ name: "abertura", displayName: "Abertura" },
	];
	if (isSuperAdmin || permissionLevel == 2) {
		optionfilterPeriod.push({ name: "limite", displayName: "Limite" });
	}

	const optionBusyImobile: TFilterFields[] = [
		{ name: "Todos", displayName: "Todos" },
		{ name: "ocupado", displayName: "Ocupado" },
		{ name: "desocupado", displayName: "Desocupado" },
	];

	const filterSinister: TFilterFields[] = [
		{ name: "documento", displayName: "Documento" },
		{ name: "nome", displayName: "Nome" },
	];

	const filterInitialParams: ListSearchParams = useMemo(() => {
		return {
			status: "open",
			tipo: "documento",
			origem: "Todos",
			typePeriod: "criacao",
			imovel: "Todos",
			typeField: "documento",
			dataIni: moment().startOf("month").subtract(3, "months").format("YYYY-MM-DD"),
			dataFim: moment().endOf("month").format("YYYY-MM-DD"),
			lmiSaldo: "0",
			sketch: "0",
		};
	}, []);

	useEffect(() => {
		setSearchParams(
			{
				...searchParams,
				status: filterInitialParams.status,
				tipo: filterInitialParams.tipo,
				imovel: filterInitialParams.imovel,
				typePeriod: filterInitialParams.typePeriod,
				typeField: filterInitialParams.typeField,
				dataini: filterInitialParams.dataIni,
				datafim: filterInitialParams.dataFim,
				lmiSaldo: filterInitialParams.lmiSaldo,
				sketch:filterInitialParams.sketch
			},
			{ replace: true }
		);
	}, []);

	const busca = useMemo(() => {
		return searchParams.get("busca") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const statusSinister = useMemo(() => {
		return searchParams.get("status") || "open";
	}, [searchParams]);

	const origemSinister = useMemo(() => {
		return searchParams.get("origem") || "";
	}, [searchParams]);

	const clientFilter = useMemo(() => {
		return searchParams.get("clientFilter") || "";
	}, [searchParams]);

	const typeField = useMemo(() => {
		return searchParams.get("typeField") || "";
	}, [searchParams]);

	const busyImobile = useMemo(() => {
		return searchParams.get("imovel") || "";
	}, [searchParams]);

	const typePeriod = useMemo(() => {
		return searchParams.get("typePeriod") || "";
	}, [searchParams]);

	const dataIniSinister = useMemo(() => {
		return searchParams.get("dataini") || ""; //moment().startOf('month').subtract(1, 'months').format("YYYY-MM-DD"));
	}, [searchParams]);

	const dataFimSinister = useMemo(() => {
		return searchParams.get("datafim") || ""; ///moment().endOf('month').format("YYYY-MM-DD"));
	}, [searchParams]);

	const managerId = useMemo(() => {
		return searchParams.get("managerId") || "";
	}, [searchParams]);

	const lmiSaldo = useMemo(() => {
		return searchParams.get("lmiSaldo") || "0";
	}, [searchParams]);

	const sketch = useMemo(() => {
		return searchParams.get("sketch") || "0";
	}, [searchParams]);

	const handleBusca = () => {
		setSearchParams(
			{
				busca,
				pagina: String(1),
				status: statusSinister,
				origem: origemSinister,
				typePeriod,
				imovel: busyImobile,
				typeField,
				dataini: dataIniSinister,
				datafim: dataFimSinister,
				clientFilter: clientFilter,
				managerId: managerId,
				lmiSaldo: lmiSaldo,
				sketch: sketch,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	useEffect(() => {
		if (hasViewPermission) {
			setIsLoading(true);
			debounce(() => {
				SinisterService.getAll(
					pagina,
					busca,
					origemSinister,
					statusSinister,
					typePeriod,
					busyImobile,
					typeField,
					clientFilter,
					dataIniSinister,
					dataFimSinister,
					managerId,
					lmiSaldo,
					sketch
				).then((result) => {
					if (result instanceof Error) {
						alert(result.message);
					} else {
						setTotalCount(result.totalCount);
						setRows(result.data);
					}
					setIsLoading(false);
				});
			});
		}
	}, [pagina, pesquisar, statusSinister]);

	const handleOpenModalCheckList = () => {
		setOpenModalCheckList(true);
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
		// SetIsSendSerasa(event.target.checked);
		// SetEnabledReason(event.target.checked)
		// (retorno:string)=> setSearchParams(
		//   { busca, pagina: String(1), status: statusSinister, origem: origemSinister, dataini: dataIniSinister, datafim: dataFimSinister,
		//   clientFilter: clientFilter, managerId:managerId, lmiSaldo:retorno },
		//   { replace: true })
	};

	const URL = window.location.protocol + "//" + window.location.host;

	return (
		<LayoutBaseDePagina
			titulo="Sinistros"
			subtitulo="Listagem de sinistros"
			barraDeFerramentas={
				<Toolbar
					filterInitialParams={filterInitialParams}
					filterFieldJson={filterSinister}
					mdCheckBox={2}
					sxCheckBox={{ textAlign: "start" }}
					textoButtonNew="novo"
					textFilterField={"Filtrar por"}
					textTypePeriod="Tipo de Período"
					textoButtonManager="Responsável pelo Sinistro"
					textoCheckBox="Lmi sem Saldo"
					textoCheckBoxSketch="Contar Rascunho"
					showInputClientFilter={checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Filter)}
					showInputOrigem={
						loggedUser?.role == "admin" && isSuperAdmin && checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Filter)
					}
					showPeriodo={checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Filter)}
					showInputBusca={true}
					showButtonNew={false} //checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Add)}
					showInputStatus={false}
					showBusyImobile={true}
					showFilterField={true}
					showTypePeriod={true}
					showInputManagerFilter={isSuperAdmin || permissionLevel == 2}
					showCheckBox={permissionLevel == 1 || permissionLevel == 2 ? true : false} // verificar se imobiliarias verão LMI SEM SALDO?
					showCheckBoxSketch={true}
					optionsBusyImobile={optionBusyImobile}
					optionsTypePeriod={optionfilterPeriod}
					onClickNovo={handleOpenModal}
					onClickButtonSearch={handleBusca}
					onChangeTextBuscaGrid={(retorno: string) =>
						setSearchParams(
							{
								busca: retorno?.replace("(Inativo)", "")?.trim(),
								pagina: String(1),
								status: statusSinister,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister,
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								managerId: managerId,
								lmiSaldo: lmiSaldo,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeStatus={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: retorno,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister,
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								managerId: managerId,
								lmiSaldo: lmiSaldo,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeOrigem={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								origemSinister: retorno,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister,
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								managerId,
								lmiSaldo: lmiSaldo,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeDataIni={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: retorno,
								datafim: dataFimSinister ? dataFimSinister : moment().endOf("month").format("YYYY-MM-DD"),
								clientFilter: clientFilter,
								managerId,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeDataFim={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister ? dataIniSinister : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: retorno,
								clientFilter: clientFilter,
								managerId,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeClientFilter={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister,
								datafim: dataFimSinister,
								clientFilter: retorno,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeManagerFilter={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister,
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								managerId: retorno,
								lmiSaldo: lmiSaldo,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeCheckBox={(retorno: boolean) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister,
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								managerId,
								lmiSaldo: `${retorno ? "1" : "0"}`,
								sketch: sketch,
							},
							{ replace: true }
						)
					}
					onChangeCheckBoxSketch={(retorno: boolean) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								origem: origemSinister,
								typePeriod,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister,
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								managerId,
								lmiSaldo,
								sketch: `${retorno ? "1" : "0"}`,
							},
							{ replace: true }
						)
					}

					onChangeTypePeriod={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								typePeriod: retorno,
								imovel: busyImobile,
								typeField,
								dataini: dataIniSinister ? dataIniSinister : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								managerId,
							},
							{ replace: true }
						);
					}}
					onChangeBusyImobile={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								typePeriod,
								imovel: retorno,
								typeField,
								dataini: dataIniSinister ? dataIniSinister : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: dataFimSinister,
								clientFilter: clientFilter,
							},
							{ replace: true }
						);
					}}
					onChangeFilterField={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								status: statusSinister,
								typePeriod,
								imovel: busyImobile,
								typeField: retorno,
								dataini: dataIniSinister ? dataIniSinister : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: dataFimSinister,
								clientFilter: clientFilter,
								tipo: retorno,
								sketch: sketch,
							},
							{ replace: true }
						);
					}}
				/>
			}
		>
			{openModal && (
				<BasicModal
					isCloseModal={() => {
						setOpenModal(false), setOpenModalCheckList(true), setPropertyOccupied(false);
					}}
					isOpenModal={openModal}
					messageType={"info"}
					showButtonCancel={true}
					textButtonConfirm={"Imóvel Ocupado"}
					textButtonCancel={"Imóvel Desocupado"}
					onclickConfirm={() => {
						setOpenModal(false), setOpenModalCheckList(true), setPropertyOccupied(true);
					}}
					ITitle={"Tipo de Sinistro"}
					IMessage={"Informe a condição do imóvel para abertura do sinistro"}
				/>
			)}

			
			<ProfileGuard requiredRole={PermissionRolesEnum.SinisterOptions} requiredAction={PermissionActionsEnum.View}>
				<TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: "auto", padding: 4 }}>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell colSpan={7}>
									<ToolbarGridSinister
										onClickTodos={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "Todos",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickAbertos={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "open",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickEmAndamento={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "in_progress",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickFinalizados={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "finalized",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickPendenciados={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "pending",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickConfissaoDebitos={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "debts_confession",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickLmiExausto={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "lmi_exausted",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickCancelado={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "canceled",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickIndenizado={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "monthly_compensation",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickRascunho={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "sketch",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}
										onClickAccord={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													status: "according",
													origem: origemSinister,
													typePeriod,
													imovel: busyImobile,
													typeField,
													dataini: dataIniSinister,
													datafim: dataFimSinister,
													clientFilter: clientFilter,
													managerId,
													lmiSaldo: lmiSaldo,
												},
												{ replace: true }
											)
										}

										selectTodos={statusSinister == "Todos"}
										selectAberto={statusSinister === "open"}
										selectEmAndamento={statusSinister === "in_progress"}
										selectConfissaoDebitos={statusSinister === "debts_confession"}
										selectFinalizado={statusSinister === "finalized"}
										selectLmiExausto={statusSinister === "lmi_exhaustion"}
										selectPendenciado={statusSinister === "pending"}
										selectIndenizado={statusSinister === "monthly_compensation"}
										selectCancelado={statusSinister === "canceled"}
										selectRascunho={statusSinister === "sketch"}
										selectAccord={statusSinister === "according"}
									/>
								</TableCell>
							</TableRow>

							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Parceiro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Endereço</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Cadastro</TableCell>
								<TableCell sx={{ color: "#7e7777b5", width: "5px" }}></TableCell>
								<TableCell sx={{ color: "#7e7777b5", width: "5px" }}> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading &&
								rows.map((row) => (
									<TableRow key={row.id} hover>
										<TableCell sx={{ fontWeight: "normal" }}>
											{row.proposal.name}
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row?.proposal.document.length > 11
													? `${row?.proposal.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")}`
													: `${row?.proposal.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
												{row.client.name}
												{row.client?.status == "inactive" && " (Inativo)"}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row?.client.document.length > 11
													? `${row?.client.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")}`
													: `${row?.client.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}`}
											</Typography>
										</TableCell>

										<TableCell sx={{ color: "#959595", fontWeight: "normal" }}>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.proposal.addressStreet != null && row.proposal.addressStreet != ""
													? `${
															row.proposal.addressComplement != null && row.proposal.addressComplement != ""
																? `${row.proposal.addressStreet} , ${row.proposal.addressNumber} - ${row.proposal.addressComplement} - ${row.proposal.addressDistrict}`
																: `${row.proposal.addressStreet} , ${row.proposal.addressNumber} - ${row.proposal.addressDistrict}`
													  }`
													: "Endereço não cadastrado"}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.proposal.addressCity != null && row.proposal.addressCity != ""
													? `${row.proposal.addressCity} - ${row.proposal.addressState}`
													: ""}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton
												size="small"
												sx={{
													background: colorBackGround(row.status),
													borderRadius: "18px",
													paddingX: "15px",
													paddingY: "1px",
													marginBottom: "5px",
												}}
											>
												<Icon sx={{ color: colorIcon(row.status), fontWeight: "normal" }}> noise_control_off_sharp_icon</Icon>
												<Typography variant="subtitle2" marginLeft={1} sx={{ color: colorText(row.status), fontWeight: "normal" }}>
													{globalTranslate(row.status, "sinister")}
												</Typography>
											</IconButton>
											<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "bold" }}>
												{row.busyProperty == "busy" ? " Imóvel ocupado" : " Imóvel desocupado"}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.responsibleId != null
													? isSuperAdmin || permissionLevel == 2
														? `${row.sinisterResponsible?.name} `
														: ""
													: ""}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
												{moment(row.createdAt).format("L")}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} sx={{ fontWeight: "normal", textAlign: "center" }}>
												{moment(row.createdAt).format("hh:mm:ss")}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton size="small" sx={{ borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
												{row.proposal.createdFrom == "web_app" ? (
													<Tooltip title="Aplicação" placement="top-start">
														<Icon sx={{ color: "#959595", fontWeight: "normal" }}> computer</Icon>
													</Tooltip>
												) : (
													<Tooltip title="Integração" placement="top-start">
														<Icon sx={{ color: "#959595", fontWeight: "normal" }}> cloud</Icon>
													</Tooltip>
												)}
											</IconButton>
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>
											<Tooltip title="Visualizar o sinistro" placement="top-start">
												<IconButton
													size="small"
													onClick={() => window.open(`${URL}/sinistros/detalhe/${row.id}`)}
													sx={{ borderRadius: "18px" }}
												>
													<RemoveRedEyeOutlinedIcon sx={{ color: "#0170B3", fontWeight: "normal" }} />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>

						{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}
							{!isLoading && totalCount > 0 && (
								<TableRow>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
											onChange={(_, newPage) =>
												setSearchParams(
													{
														busca,
														pagina: newPage.toString(),
														status: statusSinister,
														origem: origemSinister,
														typePeriod,
														imovel: busyImobile,
														typeField,
														dataini: dataIniSinister,
														datafim: dataFimSinister,
														clientFilter: clientFilter,
														managerId,
														lmiSaldo,
													},
													{ replace: true }
												)
											}
											//
											// setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
										/>
									</TableCell>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Environment.LIMITE_LINHAS - (Environment.LIMITE_LINHAS - 1)}
											{" - "}
											{Environment.LIMITE_LINHAS * (pagina - 1) + Environment.LIMITE_LINHAS * pagina < totalCount
												? Environment.LIMITE_LINHAS * pagina
												: totalCount}{" "}
											de
											<Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
												{" "}
												{totalCount}{" "}
											</Typography>
											registro(s)
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table>
				</TableContainer>
			</ProfileGuard>
			{/* } */}
		</LayoutBaseDePagina>
	);
};
