import { Email } from "@mui/icons-material";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	LinearProgress,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import { ToastContent } from "..";
import { ContextsContact } from "../../../pages/client/components/RelationComponents/components/ContextContacs";
import { IClient, IContactsGrid } from "../../../pages/client/components/RelationComponents/ContactsClient";
import { useVForm, VForm, VTextField } from "../../forms";
import { VMaskTextField } from "../../forms/VMaskTextField";
import { useToast } from "../../hooks";
import { clientService } from "../../services/api/client/clientService";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "30%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	p: 2,
	borderRadius: "6px",
};
interface IContactsModalProps {
	textButtonConfirm?: string;
	showButtonCancel?: boolean;
	isOpenModal: boolean;
	messageType: string;
	dataClient: IClient;
	data: IContactsGrid;
	onclickCancel?: () => void;
	isCloseModal?: () => void;
	clientContact?: string;
	reload?: () => void;
}

interface IFormData {
	contactName: string;
	contactEmail: string;
	contactPhone: string;
	contactType: string[];
	clientId: string;
	status: string;
	clientName?: string;
}

export const ContactsModal: React.FC<IContactsModalProps> = ({ textButtonConfirm, showButtonCancel, isOpenModal, dataClient, data, isCloseModal, reload }) => {
	showButtonCancel = true;

	const { formRef } = useVForm();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedNames, setSelectedNames] = useState([] as string[]);
	const toastHandler = useToast();
	const [clientName, setClientName] = useState("");

	useEffect(() => {
		if (data && data.id) {
			formRef.current?.setData({
				contactName: data.contactName,
				contactEmail: data.contactEmail,
				contactPhone: data.contactPhone,
				status: data.status,
				//contactName2: clientName,
			});
			setSelectedNames(data.contactType.split(","));
		}
	}, [data, isOpenModal]);

	const options = ["comercial", "financeiro", "administrativo", "sinistro", "juridico"];

	const handleSelectChange = (selectedOptions: string[]) => {
		setSelectedNames(selectedOptions);
	};

	const handleSubmit = (formData: IFormData) => {
		if (data.id) {
			//console.log("clientName", clientName);
			updateClientContact(formData, clientName);
			return;
		}
		createClientContact(formData);
	};

	const updateClientContact = async (formData: IFormData, clientName: string) => {
		try {
			if (!data.id || !formData.contactEmail || !formData.contactName || !selectedNames.length) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Alerta!!"} content={"Preencha todos os campos"} />,
				});
				return;
			}

			setIsLoading(true);

			await clientService.updateContact({
				id: data.id,
				clientId: data.clientId,
				contactName: formData.contactName,
				contactEmail: formData.contactEmail,
				contactPhone: formData.contactPhone,
				contactType: selectedNames,
				status: formData.status,
				//contactName: clientName,
			});
			setIsLoading(false);
			reload && reload();
			isCloseModal && isCloseModal();
			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title={"Sucesso"} content={"Contato atualizado com sucesso."} />,
			});
		} catch (error) {
			setIsLoading(false);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={"Alerta!!"} content={"Erro ao atualizar contato"} />,
			});
		}
	};

	const createClientContact = async (dados: IFormData) => {
		try {
			setIsLoading(true);
			if (!dados.contactName || !dados.contactEmail || !selectedNames.length) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title="Alerta!!" content="Por favor, preencha todos os campos obrigatórios." />,
				});
				return;
			}

			await clientService.createContact({
				...dados,
				clientId: dataClient.id,
				contactType: selectedNames,
			});

			setIsLoading(false);
			reload && reload();
			isCloseModal && isCloseModal();
			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title={"Sucesso"} content={"Contato adicionado com sucesso."} />,
			});
		} catch (error) {
			setIsLoading(false);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title="Alerta!!" content="Erro ao adicionar contato." />,
			});
		}
	};

	return (
		<div>
			<Modal open={isOpenModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" disablePortal>
				<Box sx={style}>
					<DialogTitle
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Icon component="span" color="primary" fontSize="large">
							connect_without_contact_Icon
						</Icon>

						<Typography id="modal-modal-title" variant="h6" color={"primary"}>
							Canais de Contato com cliente
						</Typography>
						<Typography>{dataClient.name}</Typography>
					</DialogTitle>
					<DialogContent style={{ display: "flex", justifyContent: "center" }}>
						<VForm
							id="formContacts"
							placeholder="Your placeholder"
							onPointerEnterCapture={() => console.log("Pointer entered")}
							onPointerLeaveCapture={() => console.log("Pointer left")}
							ref={formRef}
							onSubmit={handleSubmit}
						>
							<Grid container direction="column" padding={1} spacing={2}>
								{isLoading && (
									<Grid item>
										<LinearProgress variant="indeterminate" />
									</Grid>
								)}
								<Grid container item direction="row" spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<VTextField
											fullWidth
											label="Nome"
											name="contactName"
											disabled={isLoading || !data.id == undefined}
											onChange={(e) => {
												const value = e.target.value;
												const regex = /^[A-Za-zÀ-ÿ\s]*$/;
												if (!regex.test(value)) {
													e.target.value = value.slice(0, -1);
												}
											}}
										/>
										{/* <TextField
											fullWidth
											label="Nome"
											name="contactName2"
											value={clientName}
											disabled={isLoading || !data.id == undefined}
											inputProps={{ maxLength: 10 }}
											onChange={(e) => {
												const value = e.target.value;
												const regex = /^[A-Za-zÀ-ÿ\s]*$/;
												if (!regex.test(value)) {
													e.target.value = value.slice(0, -1);
												}
												setClientName(e.target.value);
											}}
										/> */}
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<ContextsContact
											names={data.contactType ? data.contactType.split(",") : selectedNames}
											disabled={false}
											options={options}
											inputLabel="Contexto"
											onSelectChange={handleSelectChange}
										/>
									</Grid>
								</Grid>
								<Grid container item direction="row" spacing={2}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<VTextField
											fullWidth
											label="E-mail"
											name="contactEmail"
											disabled={isLoading || !data.id == undefined}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton aria-label="toggle password visibility" edge="end">
															<Email />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<VMaskTextField fullWidth label="Celular" name="contactPhone" disabled={isLoading} mask="phone" />
									</Grid>
								</Grid>
								{data && data.id && (
									<Grid container item direction="row" spacing={2}>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<VTextField fullWidth label="Status" name="status" disabled={isLoading} select>
												<MenuItem value={"active"}>Ativo</MenuItem>
												<MenuItem value={"inactive"}>Inativo</MenuItem>
											</VTextField>
										</Grid>
									</Grid>
								)}
							</Grid>
						</VForm>
					</DialogContent>
					<DialogActions style={{ display: "flex", justifyContent: "center" }}>
						{
							<Button
								sx={{ background: "#7924c7" }}
								disableElevation
								variant={"contained"}
								startIcon={<Icon> checkIcon</Icon>}
								onClick={() => {
									formRef.current?.submitForm();
								}}
							>
								{textButtonConfirm}
							</Button>
						}
						{showButtonCancel && (
							<Button onClick={isCloseModal} sx={{ color: "#7924c7" }} disableElevation variant={"outlined"} startIcon={<Icon> closeIcon</Icon>}>
								Cancelar
							</Button>
						)}
					</DialogActions>
				</Box>
			</Modal>
		</div>
	);
};
