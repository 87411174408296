import { Clear, Search } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	InputAdornment,
	Modal,
	TextField,
	Typography,
	TypographyPropsVariantOverrides,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";

interface IModalHelpProps {
	iscloseModal?: () => void;
	onclickConfirm?: () => void;
	variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;
	isOpenModal: boolean;
	enableBackdropClose?: boolean;
	overrideWidthValue?: string;
	overrideHeightValue?: string;
	showButtonConfirm?: boolean;
	stylePropert?: React.CSSProperties;
	maxWidth?: string;
	maxHeight?: string;
	width?: string;
	onChangeTextSearch?: (newText: string) => void;
	textSearch?: string;
}

const Topics = [
	{
		Title: "Análises de Crédito",
		Termos: ["Como fazer uma análise", "Anexo de documentos", "Reconhecimento facial", "Reanálise"],
	},
	{
		Title: "Garantias",
		Termos: ["Realizar Contratação", "Anexo de documentos", "Renovação", "Cancelamento"],
	},
	{
		Title: "Sinistros",
		Termos: ["Abertura de sinistro", "Anexo de documentos", "Inclusão de valores", "Cancelamento"],
	},
	{
		Title: "Cancelamento",
	},
];

export const ModalHelp: React.FC<IModalHelpProps> = ({
	iscloseModal,
	onclickConfirm,
	isOpenModal,
	enableBackdropClose,
	overrideWidthValue,
	overrideHeightValue,
	stylePropert,
	maxWidth,
	maxHeight,
	width,
	variant = "h4",
	onChangeTextSearch: onChangeTextSearch,
	textSearch: textSearch = "",
}) => {
	const styleDialogContent: React.CSSProperties = stylePropert ? stylePropert : { display: "flex", justifyContent: "center" };

	const style = {
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: width ? width : "50%",
		height: "auto",
		maxWidth: maxWidth ? maxWidth : "70%",
		maxHeight: maxHeight ? maxHeight : "70%",
		bgcolor: "background.paper",
		border: "1px solid #605e5e",
		boxShadow: 24,
		overflow: "auto",
		p: 2,
		borderRadius: "6px",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#FFF",
	};

	const handleClose = (event: object, reason: string) => {
		// if (reason === "backdropClick" && isCloseModal) {
		// 	//isCloseModal();
		// }
	};

	return (
		<Modal
			open={isOpenModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			onClose={enableBackdropClose ? handleClose : () => {}}
		>
			<Box
				sx={{
					...style,
					width: overrideWidthValue ? overrideWidthValue : style.width,
					height: overrideHeightValue ? overrideHeightValue : style.height,
				}}
			>
				<DialogTitle style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 10 }}>
						<Typography id="modal-modal-title" variant={variant} sx={{ fontWeight: 300 }} color={"primary"}>
							Orientações e Ajuda Tecnologia Garantti
						</Typography>
						<IconButton color="primary" onClick={onclickConfirm} sx={{ top: 20, right: 20, position: "absolute" }}>
							<Icon sx={{ fontSize: 40 }}> cancel_Outlined_Icon</Icon>
						</IconButton>
					</Box>
				</DialogTitle>

				<Box style={{ padding: "10px", paddingLeft: 40, paddingRight: 40, display: "flex", justifyContent: "center" }}>
					<TextField
						size="small"
						value={textSearch}
						placeholder={"Pesquisar"}
						fullWidth
						sx={{ width: "50%" }}
						onChange={(e) => onChangeTextSearch?.(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" edge="start">
										<Search />
									</IconButton>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										edge="end"
										sx={{ color: "#dbd5d5" }}
										onClick={() => onChangeTextSearch?.("")}
									>
										<Clear />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Box>

				<DialogContent>
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 20 }}>
						{Topics.map((topic, index) => (
							<Accordion key={topic.Title}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
									<Typography variant={"subtitle1"} fontWeight={500}>
										{topic.Title}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={2}>
										{topic.Termos &&
											topic.Termos.map((termo, index) => (
												<Button color="primary" disableElevation variant={"outlined"} startIcon={<Icon> checkIcon</Icon>}>
													{termo}
												</Button>
											))}
									</Box>
								</AccordionDetails>
							</Accordion>
						))}
					</div>
				</DialogContent>
				{/* <DialogActions style={{ display: "flex", justifyContent: "center" }}>
					{showButtonConfirm && (
						<Button color="primary" disableElevation variant={"contained"} startIcon={<Icon> checkIcon</Icon>} onClick={onclickConfirm}>
							Fechar
						</Button>
					)}
				</DialogActions> */}
			</Box>
		</Modal>
	);
};
