import {
	Box,
	Button,
	ButtonGroup,
	Chip,
	Icon,
	LinearProgress,
	MenuItem,
	Pagination,
	Select,
	SelectChangeEvent,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { formatColorStatusReports, globalColorTextReports, globalTranslateStatusReports } from "../../utils";
import { format } from "date-fns";
import { ToolbarReports } from "./ToolbarReports";
import { useEffect, useMemo, useState } from "react";
import { useDebounce, useToast } from "../../hooks";
import { IReports, reportsServices } from "../../services/api/reports/reportsServices";
import { ToastContent } from "../ToastContent";
import { useSearchParams } from "react-router-dom";
import { RiFileExcel2Line } from "react-icons/ri";
import { ModalRequestReport } from "./ModalRequestReport";
import { ptBR } from "date-fns/locale";

interface ITableReportsProps {
	actionView: () => void;
	actionDownload: () => void;
	showView: boolean;
	showDownload: boolean;
	isloading: boolean;
}

export const TableReports = ({ isloading, showDownload, showView = false, actionDownload, actionView }: ITableReportsProps) => {
	const { debounce } = useDebounce();
	const [isLoading, setIsLoading] = useState(true);
	const [myRows, setMyRows] = useState<IReports[]>([]);
	const [totalRows, setTotalRows] = useState(0);
	const toastHandler = useToast();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isRefresh, setIsRefresh] = useState(false);
	const [isModalRequest, setisModalRequest] = useState(false);

	const page = useMemo(() => {
		return Number(searchParams.get("page") || "1");
	}, [searchParams]);

	const limit = useMemo(() => {
		return Number(searchParams.get("limit") || "5");
	}, [searchParams]);

	const handleRefresh = () => {
		setIsRefresh(!isRefresh);
	};

	const handleDownload = (id: string) => {
		console.log(id);
		reportsServices
			.getDownloadReport(id)
			.then((result) => {
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					window.open(result.url, "_blank");
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleRequestModal = () => {
		setisModalRequest(true);
	};

	useEffect(() => {
		console.log("page", page);
		setSearchParams(
			{
				...searchParams,
				page: page.toString(),
				limit: limit.toString(),
			},
			{ replace: true }
		);
	}, []);

	useEffect(() => {
		setIsLoading(true);
		debounce(() => {
			reportsServices
				.getFindReports(page, limit)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						setMyRows(result.data);
						setTotalRows(result.total);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}, [isRefresh]);

	return (
		<>
			<TableContainer sx={{ width: "100%", overflow: "auto" }}>
				<ToolbarReports onclickButtonRefresh={handleRefresh} onclickNew={handleRequestModal} />

				{isLoading && (
					<Box>
						<Box sx={{ width: "100%" }}>
							<LinearProgress sx={{ height: 6, borderRadius: 10 }} />
						</Box>
						<Stack spacing={1}>
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
						</Stack>
					</Box>
				)}

				<Table aria-label="a dense table  " size="small" sx={{ border: "1px solid #ebebeb" }}>
					<TableHead>
						{!isLoading && (
							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#464545" }}> Relatorio</TableCell>
								<TableCell sx={{ color: "#464545" }}> Criação</TableCell>
								<TableCell sx={{ color: "#464545" }}> Usuario</TableCell>
								<TableCell sx={{ color: "#464545" }}> Status </TableCell>
								<TableCell sx={{ color: "#464545" }}> </TableCell>
							</TableRow>
						)}
					</TableHead>
					<TableBody>
						{!isLoading &&
							myRows.map((row) => (
								<TableRow key={row.id} hover>
									<TableCell sx={{ fontWeight: "normal" }}>{row.report_name}</TableCell>
									<TableCell>
										<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{format(new Date(row.created_at), "dd/MM/yyyy")}
										</Typography>
										<Typography variant="caption" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{format(new Date(row.created_at), "HH:mm:ss", { locale: ptBR })}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{row.user_name}
										</Typography>
									</TableCell>
									<TableCell>
										<Chip
											label={
												new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
													? globalTranslateStatusReports(row.status)
													: globalTranslateStatusReports("expired")
											}
											variant="filled"
											sx={{
												backgroundColor:
													new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
														? globalColorTextReports(row.status)
														: globalColorTextReports("expired"),
												color:
													new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
														? formatColorStatusReports(row.status)
														: formatColorStatusReports("expired"),
											}}
										/>
									</TableCell>
									<TableCell>
										<ButtonGroup>
											{showView && (
												<Tooltip title="Visualizar">
													<Button onClick={actionView} variant="text" color="primary">
														<Icon>center_focus_strong_outlined_icon</Icon>
													</Button>
												</Tooltip>
											)}
											{showDownload && row.status === "available" && (
												<>
													{new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) && (
														<Tooltip title="Baixar Excel">
															<Button
																onClick={() => handleDownload(row.id)}
																variant="contained"
																color="secondary"
																sx={{ paddingX: 0, borderRadius: "50%" }}
															>
																<RiFileExcel2Line fontSize={22} color="whrite" />
															</Button>
														</Tooltip>
													)}

													{new Date(row.created_at) < new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) && (
														<Tooltip title="Link Expirado">
															<span>
																<Button
																	onClick={() => {}}
																	variant="outlined"
																	disabled={true}
																	sx={{ paddingX: 0, borderRadius: "50%" }}
																>
																	<Icon>event_busy_outlined_icon</Icon>
																</Button>
															</span>
														</Tooltip>
													)}
												</>
											)}
											{row.status === "in_process" && (
												<Tooltip title="Aguardando">
													<span>
														<Button
															onClick={actionDownload}
															variant="outlined"
															disabled={true}
															sx={{ paddingX: 0, borderRadius: "50%" }}
														>
															<Icon>downloading_outlined_icon</Icon>
														</Button>
													</span>
												</Tooltip>
											)}
										</ButtonGroup>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
					{totalRows === 0 && !isloading && <caption>Nenhum registro </caption>}
				</Table>

				{totalRows > 0 && !isLoading && (
					<Box
						className="xxx"
						sx={{
							border: "1px solid #e2e1e1",
							padding: 1,
							display: "flex",
							justifyContent: "space-between",
							borderTop: "none",
							alignItems: "center",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
							<Typography variant="body2" sx={{ fontWeight: "normal" }}>
								Registros por página:
							</Typography>
							<Select
								fullWidth
								value={limit.toString()}
								size="small"
								onChange={(event: SelectChangeEvent) => {
									setSearchParams(
										{
											page: "1",
											limit: event.target.value,
										},
										{ replace: true }
									);
									setIsRefresh(!isRefresh);
								}}
							>
								<MenuItem value={5}>05</MenuItem>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={25}>25</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</Box>
						<Pagination
							page={page}
							count={Math.ceil(totalRows / limit)}
							onChange={(_, newPage) => {
								setSearchParams(
									{
										page: newPage.toString(),
										limit: limit.toString(),
									},
									{ replace: true }
								),
									setIsRefresh(!isRefresh);
							}}
						/>

						<Typography variant="body2" sx={{ fontWeight: "normal" }}>
							Exibindo {page * limit - (limit - 1)}
							{" - "}
							{limit * (page - 1) + limit * page < totalRows ? limit * page : totalRows} de
							<Typography variant="overline" color={"primary"} sx={{ fontWeight: "bold", fontSize: "16px" }}>
								{" "}
								{totalRows}{" "}
							</Typography>
							registro(s)
						</Typography>
					</Box>
				)}
			</TableContainer>

			{isModalRequest && (
				<ModalRequestReport
					isCloseModal={() => {
						setisModalRequest(false);
						handleRefresh();
					}}
					onclickCloseModal={() => setisModalRequest(false)}
					isOpenModal={isModalRequest}
				/>
			)}
		</>
	);
};
