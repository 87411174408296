import {
  Box, Button, Divider, FormControlLabel, Grid, Icon, IconButton, InputAdornment, LinearProgress,
  List, ListItem, MenuItem, Paper, Switch, Typography, useMediaQuery, useTheme,
} from "@mui/material";
import { analysisService, IPlan, } from "../../../shared/services/api/analysis/analysisService";
import { useVForm, VForm, VTextField, IVFormErros, } from "../../../shared/forms";
import { AutoCompleteClient, ModalResultAnalysis, } from "../../../shared/components";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { useNavigate, useParams } from "react-router-dom";
import { ToolbarAnalysis } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import React, { useEffect, useState } from "react";
import { Email } from "@mui/icons-material";
import * as yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import "moment-timezone";
import "moment/locale/pt-br";
import { useDebounce, useToast } from "../../../shared/hooks";
import { ToastContent } from "../../../shared/components";
import { useAuthContext } from "../../../context";
import { checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum, } from "../../../shared/enums/profiles";
import { useProfile } from "../../../context/ProfileContext";
import {
  RangeStates,
  globalBackgroundPayment,
  globalColorTytle,
  globalMaskReal,
  globalRemoveMask,
  globalRemoveMaskReal,
  typeProperty,
} from "../../../shared/utils";
import { globalCheckCEP } from "../../../shared/utils/searchCEP";
import { compareTextEqual } from "../../../shared/utils";
import { PeopleService } from "../../../shared/services/api/people/PeopleService";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { Environment } from "../../../shared/environment";
import { differenceInDays } from "date-fns";

interface IFormData {
  clientId: string;
  typePerson: string;
  document: string;
  name: string;
  status?: string;
  birthDate: string;
  motherName: string;
  maritalStatus: string;
  sex: string;
  email: string;
  phone: string;

  propertyType: string;
  propertyFinally: string;
  addressZipcode: string;
  addressStreet: string;
  addressNumber: string;
  addressComplement?: string;
  addressCity: string;
  addressState: string;
  addressIBGE: string;
  addressDistrict: string;

  isTipping: string;
  sendSerasa?: string;
  sendClick?: string;
  serasaResponse?: string;

  isContractRunner: string;
  contractBegin?: string;
  contractEnd?: string;

  serasaAnalysisStatus?: string;
  paymentMethod?: string;
  assuranceMonthAmount?: string;

  rentMonthsCount: string;
  rentAmount: string;
  condominiumAmount: string;
  electricEnergyAmount: string;
  waterAmount: string;
  gasAmount: string;
  iptuAmount: string;
  rentalCost: string;
  lmi?: string;
  lmiAmount?: string;
  relation?: string;
  planlist?: string;
  employmentRelationShip: string;

  createdFrom?: string;
}

const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
  clientId: yup.string().required("Campo Obrigatório"),
  typePerson: yup.string().required("Campo Obrigatório"),
  document: yup.string().min(11).required("Campo Obrigatório"),
  name: yup.string().min(3).required("Campo Obrigatório"),
  status: yup.string().notRequired().default(""),
  birthDate: yup.string().required("Campo Obrigatório"),
  motherName: yup.string().min(3).required("Campo Obrigatório"),

  maritalStatus: yup.string().required("Campo Obrigatório"),
  sex: yup.string().required("Campo Obrigatório"),
  email: yup.string().min(3).required("Campo Obrigatório"),
  phone: yup.string().min(11).required("Campo Obrigatório"),

  propertyType: yup.string().required("Campo Obrigatório"),
  propertyFinally: yup.string().required("Campo Obrigatório"),
  addressZipcode: yup.string().required("Campo Obrigatório"),
  addressStreet: yup.string().required("Campo Obrigatório"),
  addressNumber: yup.string().required("Campo Obrigatório"),
  addressComplement: yup.string().notRequired().default(""),
  addressCity: yup.string().required("Campo Obrigatório"),
  addressState: yup.string().required("Campo Obrigatório"),
  addressIBGE: yup.string().required("Campo Obrigatório"),
  addressDistrict: yup.string().required("Campo Obrigatório"),

  isTipping: yup.string().notRequired().default(""),
  sendSerasa: yup.string().notRequired().default(""),
  sendClick: yup.string().notRequired().default(""),
  serasaResponse: yup.string().when("sendSerasa", {
    is: "not_send_serasa",
    then: yup.string().required("Campo Obrigatório"),
  }),

  isContractRunner: yup.string().notRequired().default(""),
  contractBegin: yup.string().when("isContractRunner", {
    is: "1",
    then: yup.string().required("Campo Obrigatório"),
  }),

  contractEnd: yup.string().when("isContractRunner", {
    is: "1",
    then: yup.string().required("Campo Obrigatório"),
  }),

  serasaAnalysisStatus: yup.string().notRequired().default(""),
  paymentMethod: yup.string().notRequired().default(""),
  assuranceMonthAmount: yup.string().notRequired().default(""),
  rentMonthsCount: yup.string().required("Campo Obrigatório"),
  rentAmount: yup.string().required("Campo Obrigatório"),
  condominiumAmount: yup.string().required("Campo Obrigatório"),
  electricEnergyAmount: yup.string().required("Campo Obrigatório"),
  waterAmount: yup.string().required("Campo Obrigatório"),
  gasAmount: yup.string().required("Campo Obrigatório"),
  iptuAmount: yup.string().required("Campo Obrigatório"),
  rentalCost: yup.string().required("Campo Obrigatório"),
  lmi: yup.string().required("Campo Obrigatório"),
  lmiAmount: yup.string().required("Campo Obrigatório"),
  relation: yup.string().notRequired().default(""),
  planlist: yup.string().notRequired().default(""),
  employmentRelationShip: yup.string().required("Campo obrigatório"),

  createdFrom: yup.string().notRequired().default(""),
});

export const AnalysisRequestPresentation: React.FC = () => {
  const navigate = useNavigate();
  const { id = "novo" } = useParams<"id">();
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [openModalResultAnalysis, setopenModalResultAnalysis] = useState(false);
  const [clientType, setClientType] = useState("physical");
  const [plan, setPlan] = useState<IPlan[]>([]);
  const [nome, setNome] = useState("");
  const [Doc, setDoc] = useState("");
  const [rentAmount, setRentAmount] = useState(0);
  const [assuranceBaseCalc, setAssuranceBaseCalc] = useState<number>(0);
  const [chargesBaseCalc, setChargesBaseCalc] = useState<number>(0);
  const [status, setStatus] = useState("");
  const [propFinally, setProFinally] = useState("");
  const [idcreate, setIdcreate] = useState("");
  const weeks = Array.from({ length: 49 }, (_, index) => index + 12);
  
  const [isSendSerasa, SetIsSendSerasa] = useState(true);
  const [isSendClick, SetIsSendClick] = useState(true);
  const [isTipping, setIsTipping] = useState(false);
  const [enableReason, SetEnabledReason] = useState(true);
  const [limitSerasa, setLimitSerasa] = useState("0");
  const { loggedUser } = useAuthContext();
  const { permissionLevel } = useProfile();
  const [nomeClient, setNomeClient] = useState("");
  const [clientId, setClientId] = useState("");
  const [isCPF, setIsCPF] = useState(false);
  const [statusAnalysis, setStatusAnalysis] = useState("");
  const [isContractRunner, setIsContractRunner] = useState(false);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const toastHandler = useToast();
  const { debounce } = useDebounce();

  const [vRentAmount, setVRentAmount] = useState("0");
  const [vCondominiumAmount, setVCondominiumAmount] = useState("0");
  const [vEletricAmount, setVEletricAmount] = useState("0");
  const [vWaterAmount, setVWaterAmount] = useState("0");
  const [vGasAmount, setVGasAmount] = useState("0");
  const [vIPTUAmount, setVIPTUAmount] = useState("0");
  const [vTotalAmout, setVTotalAmout] = useState("0");
  const [showReanalysis, setShowReanalysis] = useState(false);
  const [colorContractEnd,setColorContractEnd]= useState('');

 const  isValidContractEnd = (contractEndDate: string) => {
  const endDate = new Date(contractEndDate);
  const currentDate = new Date();
  const diff = differenceInDays(endDate, currentDate);
  if (diff < 365) {
    const contentMessage = "Erro, pois o contrato deve ser maior que 12 meses a partir da data de análise. Verifique!";
    toastHandler.present({
      type: "error",
      position: "top-right",
      messageOrContent: (
        <ToastContent title={"Alerta!"} content={contentMessage} />
      ),
    });
    setIsLoading(false);
    return false;
  }
return true;
};

const handleBlurEndDate = (e: React.FocusEvent<HTMLInputElement>) => {
  const isValid = isValidContractEnd(e.target.value);
  if( !isValid){
    setColorContractEnd("#eac1c1");
  }
  else{
    setColorContractEnd("");
  }
  return isValid;
    const endDate = new Date(e.target.value);
    const currentDate = new Date();

    const diff = differenceInDays(endDate, currentDate);
    if (diff < 365) {
    // e.target.style.backgroundColor = "#eac1c1";
    // parentElement.style.backgroundColor = "#eac1c1";
    setColorContractEnd("#eac1c1");
   

      toastHandler.present({
        type: "error",
        position: "top-right",
        messageOrContent: (
          <ToastContent
            title={"Fim do Contrato"}
            content={" deve ser maior que 12 meses a partir da data de análise"}
          />
        ),
      });
    } else {
      e.target.style.backgroundColor = "";
        setColorContractEnd("");
        //parentElement.style.backgroundColor = "#1c1";
      
    }
  }

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounce(() => {
    const endDateValue = event.target.value;
    const startDate = new Date(beginDate);
    const endDate = new Date(endDateValue);
    const differenceInDays = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));

    if (differenceInDays < 365) {
      toastHandler.present({
        type: "error",
        position: "top-right",
        messageOrContent: (
          <ToastContent
            title={"Fim do Contrato"}
            content={" deve ser maior que 12 meses a partir da data de análise"}
          />
        ),
      });
      
      return;
       
      // Do something if the difference is less than 365 days
    } else {
      // Do something if the difference is greater than or equal to 365 days
    }
  });
  };
   
  const handleChangeSerasa = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetIsSendSerasa(event.target.checked);
    SetEnabledReason(event.target.checked);
  };

  const handleChangeTipping = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTipping(event.target.checked);
  };

  const handleChangeContractRunner = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsContractRunner(event.target.checked);
  };

  const handleChangeClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetIsSendClick(event.target.checked);
  };

  const handleopenModalResultAnalysis = () => {
    setopenModalResultAnalysis(true);
  };


  const findPropertyType = (value: string) => {
    const selectedValue = typeProperty.find((opcao) =>
      compareTextEqual(opcao.description, value)
    );
    formRef.current?.setFieldValue("propertyType", selectedValue.description);
  };

  const handletypeProperty = (value: string) => {
    const selectedProp = typeProperty.find((opcao) =>
      compareTextEqual(opcao.description, value)
    );
    formRef.current?.setFieldValue("propertyFinally", selectedProp.type);
  };

  const numbers = Array.from(
    { length: 11 },
    (_, index) => `0000000000${index}`
  );

  const handleCPF = (value: string) => {
    const documento = value.replace(/\D/g, "").trim();
    if (numbers.includes(documento) && Environment.AMBIENTE === "DEV") {
      formRef.current?.setFieldValue(
        "name",
        `Cliente Teste  ${documento.slice(-2)}`
      );
      formRef.current?.setFieldValue("birthDate", "2000-05-01");
      formRef.current?.setFieldValue(
        "motherName",
        `Mae do Cliente Teste  ${documento.slice(-2)}`
      );
    } else {
      const ur = `${Environment.URL_API_CPF}`;
      setIsCPF(false);
      if (value?.trim()?.length && value?.trim()?.length >= 11) {
        setIsCPF(true);
      }

      PeopleService.getPersonDataByDocument(documento)
        .then((data) => {
          console.log("data", data.document);
          if (!data) {
            console.log("datadoc");
            formRef.current?.setFieldValue("name", "");
            formRef.current?.setFieldValue("birthDate", "");
            formRef.current?.setFieldValue("motherName", "");
            toastHandler.present({
              type: "error",
              position: "top-right",
              messageOrContent: (
                <ToastContent
                  title={"Erro de Documento"}
                  content={"Documento não localizado"}
                />
              ),
            });
            return;
          }
          formRef.current?.setFieldValue("name", data.naturalPersonData?.name);
          formRef.current?.setFieldValue(
            "birthDate",
            data.naturalPersonData?.birthDate
          );
          formRef.current?.setFieldValue(
            "motherName",
            data.naturalPersonData?.motherName
          );
        })
        .catch((err) => {
          const messageTitle = "Erro na pesquisa";
          const messageContent =
            "CPF não localizado na base, verifique se esta correto.";

          toastHandler.present({
            type: "error",
            position: "top-right",
            messageOrContent: (
              <ToastContent title={messageTitle} content={messageContent} />
            ),
          });
        });
    }
  };

  const handleTotalValues = () => {
    setVTotalAmout(
      (
        parseFloat(vRentAmount) +
        parseFloat(vCondominiumAmount) +
        parseFloat(vEletricAmount) +
        parseFloat(vWaterAmount) +
        parseFloat(vGasAmount) +
        parseFloat(vIPTUAmount)
      ).toString()
    );
  };

  useEffect(() => {
    if (
      vRentAmount &&
      vCondominiumAmount &&
      vEletricAmount &&
      vWaterAmount &&
      vGasAmount &&
      vIPTUAmount
    ) {
      handleTotalValues();
    }
  }, [
    vRentAmount,
    vCondominiumAmount,
    vEletricAmount,
    vWaterAmount,
    vGasAmount,
    vIPTUAmount,
  ]);

  useEffect(() => {
    if (vRentAmount && limitSerasa) {
      if (
        parseFloat(vRentAmount) <= parseFloat(limitSerasa) ||
        statusAnalysis != "pre_approved"
      ) {
        setShowReanalysis(true);
      } else {
        setShowReanalysis(false);
      }
    }
  }, [vRentAmount]);

  useEffect(() => {
    if (id !== "novo") {
      setIsLoading(true);
      analysisService.getById(`${id}`).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
          navigate("/analises");
        } else {
          setIsCPF(true);
          setNome(result.name);
          const cli = result?.client;
          const nameClient = cli?.name || "";
          const idClient = cli?.id || "";
          setNomeClient(nameClient);
          setClientId(idClient);
          setStatusAnalysis(result.status);
          setLimitSerasa(result.creditLimit);
          setVRentAmount(result.rentAmount);
          setVCondominiumAmount(result.condominiumAmount);
          setVEletricAmount(result.electricEnergyAmount);
          setVWaterAmount(result.waterAmount);
          setVGasAmount(result.gasAmount);
          setVIPTUAmount(result.iptuAmount);
          SetIsSendSerasa(
            result.sendSerasa == "not_send_serasa" ? false : true
          ); //pode vir undefined
          SetIsSendClick(result.send_click == "not_send" ? false : true);
          setIsTipping(result.isTipping == "1" ? true : false);
          setIsContractRunner(false);
          formRef.current?.setData(result);
          findPropertyType(result.propertyType || "");
        }
      });
    } else {
      formRef.current?.setData({
        clientId: "",
        typePerson: "physical",
        document: "",
        name: "",
        status: "pending",
        birthDate: "2000-05-",
        motherName: "",
        maritalStatus: "",
        sex: "",
        email: "",
        phone: "",
        rentMonthsCount: "12",
        employmentRelationShip: "",
        isTipping: "",

        sendSerasa: "send_serasa", //isSendSerasa,
        sendclick: "send", //isSendClick,
        serasaResponse: permissionLevel == 1 ? "" : "consulta serasa ",

        propertyType: "Apartamento Padrao",
        propertyFinally: "residential",
        addressZipcode: "",
        addressStreet: "",
        addressNumber: "",
        addressComplement: "",
        addressCity: "",
        addressState: "MG",
        addressIBGE: "134001",
        addressDistrict: "",

        isContractRunner: "",
        contractBegin: "",
        contractEnd: "",

        rentAmount: "0",
        condominiumAmount: "0",
        electricEnergyAmount: "0",
        waterAmount: "0",
        gasAmount: "0",
        iptuAmount: "0",
        rentalCost: "only_rent",
        createdFrom: "web_app",
      });
    }
  }, [id]);

  const verifyCellPhoneAndCPF = async (document: string, cellphone: string) => {
    return new Promise<boolean>((resolve) => {
      analysisService
        .verifyCellPhoneAndCPF(document, cellphone)
        .then((result: any) => {
          if (result instanceof Error) {
            const titleMessage = "Alerta!!";
            const contentMessage = "Erro: " + result?.message;

            toastHandler.present({
              type: "error",
              position: "top-right",
              messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
              ),
            });
            return resolve(true);
          }
          return resolve(false);
        })
        .catch((err: any) => {
          const { titleMessage, contentMessage } = returnMessageError(err);

          toastHandler.present({
            type: "error",
            position: "top-right",
            messageOrContent: (
              <ToastContent title={titleMessage} content={contentMessage} />
            ),
          });

          return resolve(true);
        });
    });
  };

  const handleSave = async (dados: IFormData) => {
    setIsLoading(true);
    let phone = dados.phone.replace(/\D/g, "").trim();
    phone = phone.length > 11 ? phone.slice(0, 11) : phone;
    dados.document = dados.document.replace(/\D/g, "").trim();
    dados.phone = phone;
    dados.gasAmount = globalRemoveMaskReal(dados.gasAmount);
    dados.rentAmount = globalRemoveMaskReal(dados.rentAmount);
    dados.iptuAmount = globalRemoveMaskReal(dados.iptuAmount);
    dados.waterAmount = globalRemoveMaskReal(dados.waterAmount);
    dados.email = dados?.email?.trim();
    dados.name = dados?.name?.trim();
    dados.condominiumAmount = globalRemoveMaskReal(dados.condominiumAmount);
    dados.electricEnergyAmount = globalRemoveMaskReal(dados.electricEnergyAmount);
    dados.sendSerasa =
      permissionLevel == 1 && isSendSerasa == false
        ? "not_send_serasa"
        : "send_serasa";
    dados.sendClick = isSendClick == false ? "not_send" : "send";
    dados.isTipping = isTipping == true ? "1" : "0";
    dados.serasaResponse = permissionLevel == 1 ? dados.serasaResponse : "obrigatorio envio serasa";
    dados.serasaAnalysisStatus = "approved";
    dados.createdFrom = "web_app";
    dados.status = "pending";
    dados.rentalCost = "only_rent";
    dados.paymentMethod = "billet";
    dados.assuranceMonthAmount = "0";
    dados.relation = "{}";
    dados.planlist = "{}";
    dados.lmi = "0";
    dados.lmiAmount = "0";
    dados.isContractRunner = isContractRunner == true ? "1" : "0";

    //if (dados.isContractRunner == "0") {
      dados.contractBegin = (dados.isContractRunner == "0")?"" :dados.contractBegin ;
      dados.contractEnd = (dados.isContractRunner == "0")?"":dados.contractEnd;
      if(dados.contractEnd!='' && !isValidContractEnd(dados.contractEnd?dados.contractEnd:''  ))  {
        return false
      }
    //}
     if (dados.isContractRunner == "1" &&  dados.contractEnd=='') {
      const contentMessage = "Erro, data final de contrato não pode ficar vazia. Verifique!";
      toastHandler.present({
      type: "error",
      position: "top-right",
      messageOrContent: (
        <ToastContent title={"Alerta!"} content={contentMessage} />
      ),
      });
    }
    //   dados.contractEnd = "";
    // }


    if (phone[2] != "9") {
      const contentMessage =
        "Erro, pois o digito 9 não foi informado no campo do celular. Verifique!";
      toastHandler.present({
        type: "error",
        position: "top-right",
        messageOrContent: (
          <ToastContent title={"Alerta!"} content={contentMessage} />
        ),
      });
      setIsLoading(false);
      return;
    } else if (await verifyCellPhoneAndCPF(dados.document, dados.phone)) {
      setIsLoading(false);
      return;
    }

    formValidationSchema
      .validate({ ...dados }, { abortEarly: false })
      .then((dadosvalidados) => {
        setIsLoading(true);
        if (dadosvalidados.isContractRunner == "0") {
          delete dadosvalidados.contractBegin;
          delete dadosvalidados.contractEnd;
        }

        analysisService.create(dadosvalidados, id).then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setProFinally(result.propertyFinally);
            setRentAmount(parseFloat(result.rentAmount));
            setLimitSerasa(result.creditLimit ?? "");
            setChargesBaseCalc(
              parseFloat(result.rentAmount) +
              parseFloat(result.condominiumAmount) +
              parseFloat(result.iptuAmount)
            );
            // if (result.baseCalc === "encargos") {
            //   setAssuranceBaseCalc(
            //     parseFloat(result.rentAmount) +
            //     parseFloat(result.condominiumAmount) +
            //     parseFloat(result.iptuAmount)
            //   );
            // } else {
              setAssuranceBaseCalc(parseFloat(result.rentAmount));
            //}
            setStatus(result.status ? result.status : "");
            setIdcreate(result.id);
            if (
              result.status === "issued" ||
              result.status === "awaiting_biometria"
            ) {
              const pplano = result.planlist ? result.planlist : "";
              const planos = JSON.parse(pplano); //regra buscar planos somente que a flag Analysis==1
              const plansAnalysisSelect = planos.filter(
                (rowPlan: IPlan) =>
                  rowPlan.flag_analysis == undefined ||
                  rowPlan?.flag_analysis == "1"
              );
              setPlan(plansAnalysisSelect);
              
            }
            setopenModalResultAnalysis(true);
          }
        });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErros: IVFormErros = {};
        errors.inner.forEach((error) => {
          if (!error.path) return;
          validationErros[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErros);
        setIsLoading(false);
      });
    console.log("LimiteSerasa:", limitSerasa);
  };

  const checkCEP = (e: any) => {
    const cep = e.target.value.replace(/\D/g, "");
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        formRef.current?.setFieldValue("addressZipcode", data.cep);
        formRef.current?.setFieldValue("addressStreet", data.logradouro);
        formRef.current?.setFieldValue("residenceTypeo", "");
        formRef.current?.setFieldValue("addressDistrict", data.bairro);
        formRef.current?.setFieldValue("addressCity", data.localidade);
        formRef.current?.setFieldValue("addressState", data.uf);
        formRef.current?.setFieldValue("addressIBGE", data.ibge);
      });
  };
  return (
    <LayoutBaseDePagina
      titulo="Análises"
      subtitulo={
        id === "novo"
          ? "Nova análise de crédito"
          : `Alteração de análise - Reanálise [ ${nome} ]`
      }
      barraDeFerramentas={
        <ToolbarAnalysis
          clickAnalysis={save}
          showButtonAnalysis={
            !isLoading &&
              checkByProfileGuard(
                PermissionRolesEnum.AnalysisOptions,
                PermissionActionsEnum.Conclude
              )
              ? true
              : false
          }
          clickCancel={handleopenModalResultAnalysis}
          clickBack={() => navigate("/analises")}
          showButtonCancel={false}
          showButtonProposal={false}
          showButtonEmailresend={false}
          showButtonReAnalysis={false}
          showButtonAnalysisLoading={!showReanalysis && !isLoading} //
        />
      }
    >
      {openModalResultAnalysis && (
        <ModalResultAnalysis
          isCloseModal={() => setopenModalResultAnalysis(false)}
          isOpenModal={openModalResultAnalysis}
          messageType={
            status === "issued" || status === "awaiting_biometria" || status === "pre_approved"
              ? "success"
              : "error"
          }
          showButtonCancel={false}
          textButtonConfirm={
            status === "issued" ? "Contratar depois" : "Fechar"
          }
          onclickConfirm={() => {
            handleopenModalResultAnalysis();
          }}
          ITitle={
            status === "issued"
              ? "Análise aprovada"
              : status === "awaiting_biometria"
                ? "Aprovada"
                : status === "pre_approved" && limitSerasa.toString() !== "0"
                  ? "Análise Pré - Aprovada"
                  : "Análise Recusada"
          }
          IMessage={[

            <Grid
              container
              item
              sx={{ textAlign: "center", justifyContent: "center" }}
              display={"flex"}
              flexDirection={smDown ? "column" : "row"}
              gap={1}
            >
              {status === "pre_approved" && (
                <Typography
                  variant="h5"
                  color="primary"
                >
                  {limitSerasa.toString() !== "0" ? `Limite de crédito: R$ ${globalMaskReal(limitSerasa)}` : ""}
                </Typography>
              )}
              {plan?.map((row) => (
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  margin={1}
                  sx={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  display={"flex"}
                  flexDirection={smDown ? "column" : "row"}
                  key={row.id}
                  padding={1}
                >
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                      borderRadius: "80px 2px 80px 2px",
                      padding: 4,
                      boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                    }}
                  >
                    <Typography variant="h5" sx={{ color: "text.secondary" }}>
                    { (row && (row?.planId.includes('__charges') ) ) ? row.plan.description+' +' :( row?.plan?.description+'') }
                      {/* {row.plan?.description} */}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ color: "#7924c7 ", fontWeight: 700, fontFamily: "arial", }}
                    >
                      R$
                      {propFinally === "residential"
                        ? globalMaskReal((( (row?.planId.includes('__charges') ) ? chargesBaseCalc: assuranceBaseCalc) * (parseFloat(row.residentialFee) / 100.0)).toFixed(2))
                        : globalMaskReal((( (row?.planId.includes('__charges') ) ? chargesBaseCalc: assuranceBaseCalc) * (parseFloat(row.commercialFee) / 100.0)).toFixed(2)
                        )}
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "text.secondary" }}
                      >
                        /mes
                      </Typography>
                    </Typography>
                    <Button
                      color="primary"
                      disableElevation
                      variant={"contained"}
                      startIcon={status === 'issued' ? <Icon>checkIcon</Icon> : <Icon>fingerprint</Icon>}
                      // startIcon={<Icon> {{status=='issued'? checkIcon: fingerprint}}</Icon>}
                      onClick={
                        status === "issued"
                          ? () =>
                            navigate(
                              `/analises/contratacao?analise=${idcreate}&plano=${row.planId}`
                            )
                          : () => navigate("/analises/")
                      }
                      //                               {() => navigate(`/analises/contratacao?analise=${idcreate}&plano=${row.id}`)}
                      sx={{ mt: 1, mb: 1 }}
                    >
                      {
                        status === "issued"
                        ? "Contratar agora"
                        : 
                          "Aguardando biometria"
                          }
                      {/* Aguardando Biometria */}
                    </Button>
                    <Divider />
                    <nav aria-label="secondary mailbox folders">
                      <List>
                        <ListItem
                          sx={{
                            textAlign: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            padding: "1px",
                          }}
                        >
                          <Typography variant="caption">
                            Taxa Garantti
                          </Typography>
                          <Typography variant="subtitle1">
                            {propFinally === "residential"
                              ? row.residentialFee
                              : row.commercialFee}{" "}
                            %
                          </Typography>
                        </ListItem>
                        <ListItem
                          sx={{
                            textAlign: "center",
                            justifyContent: "center",
                            padding: "1px",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontFamily: "cursive", color: "#1ac266" }}
                          >
                            LMI {row.lmi} x
                          </Typography>
                        </ListItem>
                        <ListItem
                          sx={{
                            textAlign: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            padding: "1px",
                          }}
                        >
                          <Typography variant="caption">Valor LMI</Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontFamily: "cursive" }}
                          >
                            R${" "}
                            {globalMaskReal((
                               ( (row?.planId.includes('__charges') ) ? chargesBaseCalc: assuranceBaseCalc) * parseInt(row.lmi)
                               * 1.0).toFixed(2))}
                          </Typography>
                          <Typography   sx={{ color: "#7924c7 ", fontWeight: 400, fontFamily: "arial", }}>
                          {(row?.planId.includes("__charges") ? ' (aluguel + encargos)': '( aluguel )')}
														</Typography>
                        </ListItem>
                        <ListItem
                          sx={{ justifyContent: "left", padding: "1px" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#39c463" }}
                          >
                            <CheckIcon />
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Sem fiador ou caução
                          </Typography>
                        </ListItem>
                        <ListItem
                          sx={{ justifyContent: "left", padding: "1px" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#39c463" }}
                          >
                            <CheckIcon />
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Assessoria Jurídica gratuita.
                          </Typography>
                        </ListItem>
                        <ListItem
                          sx={{ justifyContent: "left", padding: "1px" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#39c463" }}
                          >
                            <CheckIcon />
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Contratação 100% digital
                          </Typography>
                        </ListItem>
                        <ListItem
                          sx={{ justifyContent: "left", padding: "1px" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#39c463" }}
                          >
                            <CheckIcon />
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Recebimento Inadimplência
                          </Typography>
                          </ListItem>
                          <ListItem sx={{ justifyContent: "left", padding: "1px" }}>
														<Typography variant="subtitle1" sx={{ color: "#39c463" }}>
															<CheckIcon />
														</Typography>
														<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
															Limite de saída {" "}	
															{(
																( (row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) * parseInt( row.coverage || '0'  )  )
															).toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
															})
															
															}
                              {" absorvido do LMI"}
														</Typography>
													
                        </ListItem>
                      </List>
                    </nav>
                  </Box>
                </Grid>
              ))}

              <Grid
                container
                item
                sm={12}
                sx={{
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
                display={"flex"}
                flexDirection={smDown ? "column" : "row"}
                padding={2}
              >
                <Button
                  color="primary"
                  disableElevation
                  variant={"outlined"}
                  startIcon={<Icon> checkIcon</Icon>}
                  onClick={() => navigate("/analises/")}
                >
                  {status === "issued" ? "Contratar depois" : "Fechar"}
                </Button>
              </Grid>
            </Grid>,
          ]}
        />
      )}

      <Box sx={{ width: "100%", maxWidth: "1300px" }}>
        <VForm
          id="formIdSave"
          placeholder="Your placeholder"
          onPointerEnterCapture={() => console.log("Pointer entered")}
          onPointerLeaveCapture={() => console.log("Pointer left")}
          ref={formRef}
          onSubmit={handleSave}
        >
          <Box
            margin={1}
            marginY={1}
            display="flex"
            flexDirection="column"
            component={Paper}
            variant="outlined"
          >
            <Grid container item direction="column" padding={2} spacing={2}>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h6" color={globalColorTytle}>
                    Dados do Inquilino
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2} marginBottom={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AutoCompleteClient
                    isExternalLoading={isLoading}
                    namecampo="clientId"
                    labelSelect={"Corretor / Imobiliaria"}
                    nameDefault={nomeClient}
                    clientDefault={clientId}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Grid
                    item
                    sx={{
                      width: "100%",
                      border: "1px solid #5d07ce",
                      background: "#ebdcff",
                      padding: "0.2% 2.3%",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography variant="caption" color={"primary"}>
                      Atenção: É obrigatório o preenchimento do e-mail e celular
                      do locatário. O preenchimento do e-mail e/ou celular que
                      não seja do locatário poderá ocasionar em recusa ou em não
                      contratação da garantia.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Tipo de Cliente"
                    name="typePerson"
                    disabled={true}
                    select
                    onChange={(e) => setClientType(e.target.value)}
                  >
                    <MenuItem value={"physical"}>Fisica</MenuItem>
                    <MenuItem value={"legal"}>Juridica</MenuItem>
                  </VTextField>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  {clientType === "physical" ? (
                    <VMaskTextField
                      fullWidth
                      label="CPF"
                      name="document"
                      disabled={id != "novo" ? true : isLoading}
                      mask="cpf"
                      onChange={(e) => setDoc(e.target.value)}
                      onBlur={(e) => handleCPF(e.target.value)}
                    // onBlur={(e) => globalSearchCPF(e.target.value)}
                    />
                  ) : (
                    <VMaskTextField
                      fullWidth
                      label="Documento"
                      name="document"
                      disabled={id != "novo" ? true : isLoading}
                      mask="cnpj"
                      onChange={(e) => setDoc(e.target.value)}
                      onBlur={(e) => handleCPF(e.target.value)}
                    // onBlur={(e) => globalSearchCPF(e.target.value)}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Nome"
                    name="name"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Nome mãe"
                    name="motherName"
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Data de nascimento"
                    name="birthDate"
                    disabled={true}
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Email"
                    name="email"
                    disabled={isLoading}
                    trimEmptySpaces={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <Email />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid container item direction="row" spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      label="Relação de trabalho "
                      name="employmentRelationShip"
                      disabled={isLoading}
                      select
                    >
                      <MenuItem value={"retired"}>Aposentado</MenuItem>
                      <MenuItem value={"freelance"}>Autônomo</MenuItem>
                      <MenuItem value={"entrepreneur"}>Empresário</MenuItem>
                      <MenuItem value={"student"}>Estudante</MenuItem>
                      <MenuItem value={"civil_servant"}>
                        Funcionário Púlico
                      </MenuItem>
                      <MenuItem value={"private_servant"}>
                        Funcionário CLT
                      </MenuItem>
                      <MenuItem value={"liberal"}>Liberal</MenuItem>
                      <MenuItem value={"rent_income"}>Renda Alugueis</MenuItem>
                    </VTextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      label="Sexo"
                      name="sex"
                      disabled={isLoading}
                      select
                    >
                      <MenuItem value={"male"}>Masculino</MenuItem>
                      <MenuItem value={"female"}>Feminino</MenuItem>
                    </VTextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      label="Estado Civil "
                      name="maritalStatus"
                      disabled={isLoading}
                      select
                    >
                      <MenuItem value={"single"}>Solteiro</MenuItem>
                      <MenuItem value={"married"}>Casado</MenuItem>
                      <MenuItem value={"widower"}>Viúvo</MenuItem>
                      <MenuItem value={"divorced"}>Divorciado</MenuItem>
                      <MenuItem value={"stable_union"}>União Estável</MenuItem>
                    </VTextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <VMaskTextField
                      fullWidth
                      label="Celular"
                      name="phone"
                      disabled={isLoading}
                      mask="cellphone"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <VMaskTextField
                      fullWidth
                      label="Telefone"
                      name="mobilePhone"
                      disabled={isLoading}
                      mask="cell"
                    />
                  </Grid>
                </Grid>
                <Grid container item direction="row" spacing={2}>
                  {permissionLevel === 1 && (
                    <Grid item xs={12} md={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={handleChangeTipping}
                            name="isTipping"
                            color="secondary"
                            defaultChecked={isTipping}
                            disabled={permissionLevel == 1 ? false : true}
                          />
                        }
                        label="Tombamento"
                      />
                    </Grid>
                  )}

                  {permissionLevel === 1 && (
                    <Grid item xs={12} md={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={handleChangeSerasa}
                            name="sendSerasa"
                            color="primary"
                            defaultChecked={isSendSerasa}
                            disabled={permissionLevel == 1 ? false : true}
                          />
                        }
                        label="Enviar Serasa"
                      />
                    </Grid>
                  )}

                  {permissionLevel === 1 && (
                    <Grid item xs={12} md={8}>
                      <VTextField
                        fullWidth
                        label="Motivo não envio para Serasa"
                        name="serasaResponse"
                        disabled={enableReason}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box
            margin={1}
            marginY={1}
            display="flex"
            flexDirection="column"
            component={Paper}
            variant="outlined"
          >
            <Grid container item direction="column" padding={2} spacing={2}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant="indeterminate" />
                </Grid>
              )}
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h6" color={globalColorTytle}>
                    Dados do Imóvel{" "}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Tipo Imóvel "
                    name="propertyType"
                    disabled={isLoading}
                    select
                    onChange={(e) => {
                      handletypeProperty(e.target.value);
                    }}
                  >
                    {typeProperty.map((element, key) => (
                      <MenuItem
                        key={element.description}
                        value={element.description}
                      >
                        {element.description}
                      </MenuItem>
                    ))}
                  </VTextField>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Finalidade"
                    name="propertyFinally"
                    disabled={true}
                    select
                  >
                    <MenuItem value="residential">Residencial</MenuItem>
                    <MenuItem value="commercial">Comercial</MenuItem>
                  </VTextField>
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VMaskTextField
                    fullWidth
                    label="CEP"
                    name="addressZipcode"
                    disabled={isLoading}
                    mask="cep"
                    //onBlur={globalCheckCEP}
                    onBlur={checkCEP}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Rua"
                    name="addressStreet"
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Numero"
                    name="addressNumber"
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Complemento"
                    name="addressComplement"
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Bairro"
                    name="addressDistrict"
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Cidade"
                    name="addressCity"
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Estado"
                    name="addressState"
                    disabled={isLoading}
                    select
                  >
                    {RangeStates.map(({ value, label }, key) => (
                      <MenuItem value={value}>{label}</MenuItem>
                    ))}
                  </VTextField>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Cód IBGE"
                    name="addressIBGE"
                    disabled={true}
                    sx={{ border: "none" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box
            margin={1}
            display="flex"
            flexDirection="column"
            component={Paper}
            variant="outlined"
          >
            <Grid container item direction="column" padding={2} spacing={2}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant="indeterminate" />
                </Grid>
              )}
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={11}>
                  <Typography variant="h6" color={"#1c1b1b"}>
                    Valores da locação
                  </Typography>
                </Grid>
              </Grid>

              {statusAnalysis === "pre_approved" && (
                <Grid container item direction="row" spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        border: "1px solid #eac1c1",
                        background: globalBackgroundPayment("overdue"),
                        padding: "0.2% 2.3%",
                        borderRadius: "4px",
                      }}
                    >
                      <Box display={"flex"} gap={1} alignItems={"center"}>
                        <Typography variant="body1" sx={{ color: "#d54949" }}>
                          Analise Pré-Aprovada com valor de até
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#d54949",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          {parseFloat(`${limitSerasa}`).toLocaleString(
                            "pt-br",
                            { style: "currency", currency: "BRL" }
                          )}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "#d54949", fontWeight: "bold" }}
                      >
                        Próximos passos:
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#d54949" }}>
                        Você deverá reduzir o valor do aluguel  ou
                        negociar junto ao proprietário ou ainda buscar outro
                        imóvel que possua os valores e taxas compatíveis com
                        este valor total pré-aprovado.
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#d54949" }}>
                        Será necessário ajustar o valor do aluguel (conforme o
                        limite aprovado) e, caso tenha buscado outro imóvel,
                        alterar os DADOS DO IMÓVEL. (Não é necessário realizar
                        uma nova análise)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={handleChangeContractRunner}
                        name="isContractRunner"
                        color="primary"
                        defaultChecked={isContractRunner}
                        disabled={false}
                      />
                    }
                    label="Contrato de locação já existe?"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Inicio do contrato de locação"
                    name="contractBegin"
                    disabled={isLoading || !isContractRunner}
                    type="date"
                    onChange={(e) => setBeginDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                  <VTextField
                    sx={{  background: `${colorContractEnd}`}}
                    fullWidth
                    label="Fim do contrato de locação"
                    name="contractEnd"
                    disabled={isLoading || !isContractRunner}
                    //onChange={handleEndDateChange}
                    onBlur={handleBlurEndDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    
                    type="date"
                  />
                </Grid>
                
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VTextField
                    fullWidth
                    label="Quantidade de meses Vigência"
                    name="rentMonthsCount"
                    disabled={isLoading}
                    select
                  >
                    {weeks.map((element, key) => (
                      <MenuItem value={element}>{element}</MenuItem>
                    ))}
                  </VTextField>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VMaskTextField
                    fullWidth
                    label="Valor do Aluguel"
                    name="rentAmount"
                    disabled={isLoading}
                    mask="real"
                    onBlur={(e) =>
                      setVRentAmount(globalRemoveMask(e.target.value))
                    }
                    sx={{
                      background: !showReanalysis
                        ? globalBackgroundPayment("overdue")
                        : "#Ffff",
                    }}
                  />
                  {!showReanalysis && (
                    <Typography variant="caption" color={"#e71d1d"}>
                      Valor maior que o limite de{" "}
                      {parseFloat(`${limitSerasa}`).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VMaskTextField
                    fullWidth
                    label="Valor do Condomínio"
                    name="condominiumAmount"
                    disabled={isLoading}
                    mask="real"
                    onBlur={(e) =>
                      setVCondominiumAmount(globalRemoveMaskReal(e.target.value))
                    }
                  />
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VMaskTextField
                    fullWidth
                    label="Valor da Energia"
                    name="electricEnergyAmount"
                    disabled={isLoading}
                    mask="real"
                    onBlur={(e) =>
                      setVEletricAmount(globalRemoveMask(e.target.value))
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VMaskTextField
                    fullWidth
                    label="Valor da Agua"
                    name="waterAmount"
                    disabled={isLoading}
                    mask="real"
                    onBlur={(e) =>
                      setVWaterAmount(globalRemoveMask(e.target.value))
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VMaskTextField
                    fullWidth
                    label="Valor do Gas"
                    name="gasAmount"
                    disabled={isLoading}
                    mask="real"
                    onBlur={(e) =>
                      setVGasAmount(globalRemoveMask(e.target.value))
                    }
                  />
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <VMaskTextField
                    fullWidth
                    label="Valor IPTU"
                    name="iptuAmount"
                    disabled={isLoading}
                    mask="real"
                    onBlur={(e) =>
                      setVIPTUAmount(globalRemoveMask(e.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    gap={2}
                    alignItems={"center"}
                  >
                    <Typography variant="body1">
                      Valor total da locação:
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={2}
                      alignItems={"center"}
                      sx={{
                        background: "#ffffff",
                        padding: 0.5,
                        borderRadius: 1,
                        color: "gray",
                      }}
                    >
                      <Typography variant="h6">
                        {parseFloat(`${vTotalAmout}`).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </VForm>
      </Box>
    </LayoutBaseDePagina>
  );
};
