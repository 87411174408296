import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useVForm, VForm } from "../../../forms";
import { useToast } from "../../../hooks";
import { FinancialService } from "../../../services/api/financial/FinancialService";
import { ProposalService } from "../../../services/api/proposal/ProposalService";
import { LoadingComp } from "../../loading/Loading";
import { ToastContent } from "../../ToastContent";
import { ToolbarTransferClients } from "../../toolbarGridClientes/ToolbarGridTransferClients";
import { ListTransport } from ".././components/ListTranport";
import { ModalConfirmPortable } from ".././components/ModalConfirmPortable";
import { set } from "lodash";

export type TAutoCompleteOption = {
	id: string;
	label: string;
};

export default function TabsPortable() {
	const [value, setValue] = React.useState("1");
	const { formRef } = useVForm();
	const [isLoading, setIsLoading] = React.useState(false);
	const [rows, setRows] = useState<any[]>([]);
	const [dataClientFilter, setDataClientFilter] = useState("");
	const [dataClientFilter2, setDataClientFilter2] = useState("");
	const toastHandler = useToast();
	const [Refresh, setRefresh] = useState(false);
	const [ListSelect, setListSelect] = useState<string[]>([]);
	const [modalConfirm, setModalConfirm] = useState(false);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
		setValue(newValue);
	};

	const handleOpenModal = () => {
		if (ListSelect.length > 0) {
			if (dataClientFilter2.length > 0 && dataClientFilter2 != dataClientFilter) {
				setModalConfirm(true);
			} else {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Imobiliária destino não informada ou igual a origem"} />,
				});
			}
		} else {
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={"Erro"} content={"Necessario selecionar uma garantia para transferir"} />,
			});
		}
	};

	//transferir a garantia
	const handleTransferProposal = async (item: string, destiny: string) => {
		ProposalService.updateProposalClient(item, destiny).then((result) => {
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao realizar a transferência"} />,
				});
			} else {
				setRefresh(!Refresh);
			}
		});
	};

	//transferir a parcela
	const handleTransferInstallment = async (item: string, destiny: string) => {
		setIsLoading(true);
		FinancialService.updateInstallmentClient(item, destiny).then((result) => {
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={`Erro ao realizar a transferência da installment ${item}`} />,
				});
			}
		});
		setIsLoading(false);
	};

	const handleClick = () => {
		setModalConfirm(false);
		setIsLoading(true);
		if (ListSelect.length > 0) {
			setIsLoading(true);
			ListSelect.map((item) => {
				if (dataClientFilter2.length > 0 && dataClientFilter2 != dataClientFilter) {
					setTimeout(() => {
						handleTransferProposal(item, dataClientFilter2);
						console.log("Transferindo garantia ", item, " para ", dataClientFilter2);
						rows.map((x) => {
							if (x.id === item) {
								if (x.installment.length > 0) {
									x.installment.map((y: { status: string; id: any }) => {
										if (y.status === "pending") {
											console.log("Transferindo parcela", y.id);
											handleTransferInstallment(y.id, dataClientFilter2);
										}
									});
								}
							}
						});
					}, 200); //tempo de espera para executar a proxima transferencia
				}
			});
			setDataClientFilter2("");
			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title={"Sucesso"} content={"Transferência realizada com sucesso"} />,
			});
		}
		setRows([]);
		setIsLoading(false);
	};

	//carregar as garantias com as parcelas
	useEffect(() => {
		setIsLoading(true);
		if (dataClientFilter != undefined && dataClientFilter.length > 0) {
			ProposalService.getIntallmentsByClientId(dataClientFilter).then((result) => {
				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={"Erro"} content={"Erro ao realizar a consulta"} />,
					});
				} else {
					setRows(result);
				}
			});
		} else {
			setRows([]);
		}
		setIsLoading(false);
	}, [Refresh]);

	return (
		<>
			<LoadingComp isopen={isLoading} />
			<Box sx={{ width: "100%", typography: "body1" }}>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<TabList onChange={handleChange} aria-label="primary" indicatorColor="primary" role="navigation">
							<Tab label="Garantias" value="1" />
							<Tab label="Parcelas" value="4" />
							{/* <Tab label="Análises" value="2" />
							<Tab label="Sinistros" value="3" /> */}
							{/* <Tab label="Taxa Setup" value="5" /> */}
						</TabList>
					</Box>
					<TabPanel value="1">
						<VForm id="formIdSave" ref={formRef} placeholder="Your placeholder"
							onPointerEnterCapture={() => console.log('Pointer entered')}
							onPointerLeaveCapture={() => console.log('Pointer left')}
							onSubmit={() => {}}>
							<Grid container item direction="row" spacing={2}>
								<Box sx={{ width: "100%" }}>
									<ToolbarTransferClients
										onChangeClientFilter={(value) => {
											setDataClientFilter(value);
											setRefresh(!Refresh);
										}}
										onChangeClientFilter2={(value) => {
											setDataClientFilter2(value);
										}}
										onChangeClientFilterName2={(value) => {}}
										loading={isLoading}
										onClickTransfer={handleOpenModal}
									/>
								</Box>
							</Grid>
						</VForm>
						<ListTransport
							rows={
								rows.length > 0
									? rows.map((row) => {
											return {
												id: row.id,
												label: row.name,
												document: row.document,
												status: row.status,
												Installments: row.installment
													? row.installment.map((item: { id: any; clientId: any; status: any }) => {
															return {
																id: item.id,
																client: item.clientId,
																status: item.status,
															};
													  })
													: [],
											};
									  })
									: []
							}
							IDS={(selectedItems) => {
								selectedItems && dataClientFilter.length > 0 ? setListSelect(selectedItems) : setListSelect([]);
							}}
						/>
					</TabPanel>
					<TabPanel value="2">Analises</TabPanel>
					<TabPanel value="3">Sinistros</TabPanel>
					<TabPanel value="4">Parcelas</TabPanel>
					<TabPanel value="5">Taxa Setup</TabPanel>
				</TabContext>
			</Box>

			{modalConfirm && (
				<ModalConfirmPortable
					isOpenModal={modalConfirm}
					onCloseModal={() => setModalConfirm(false)}
					overrideWidthValue={"50%"}
					onclickConfirm={handleClick}
				/>
			)}
		</>
	);
}
