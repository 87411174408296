import { Clear, PrintOutlined, Search } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Switch,
	SxProps,
	TextField,
	Theme,
	Tooltip,
} from "@mui/material";
import moment from "moment";
import "moment/locale/pt-br";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../../context/ProfileContext";
import { ListSearchParams } from "../../../pages/analysis/types/list-search-params.type";
import { ToastContent } from "../../../shared/components";
import { useToast } from "../../../shared/hooks";
import { IListagemUsuario, userService } from "../../../shared/services/api/user/userService";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../enums/profiles";
import { Environment } from "../../environment";
import { checkByProfileGuard } from "../../guards/ProfileGuard";
import { clientService } from "../../services/api/client/clientService";
import { PermissionServices } from "../../services/api/permissions/PermissionService";
import { TFilterFields } from "../../types/filterfields.type";
import { RangeStates } from "../../utils";
import { returnMessageError } from "../../utils/returnMessageError";

export type TAutoCompleteOption = {
	id: string;
	label: string;
};

interface IPermission {
	id: string;
	description: string;
	level: number;
}

interface IToolbarProps {
	mdCheckBox?: number;
	sxCheckBox?: SxProps<Theme>;
	sxFormControlLabel?: SxProps<Theme>;
	sxPaper?: SxProps<Theme>;

	textSearchGrid?: string;
	showInputBusca?: boolean;
	onChangeTextBuscaGrid?: (newText: string) => void;
	aoLimparTextBuscaGrid?: (textEmpty: string) => void;

	showTitle?: boolean;
	onChangeTitle?: (text: string) => void;
	showStatusContract?: boolean;
	showDateRegister?: boolean;
	onChangeDateRegister?: (text: string) => void;
	showButtonFind?: boolean;
	onClickButtonFind?: () => void;
	textButtonFind?: string;
	isLoaddingButtonFind?: boolean;
	textoButtonNewContract?: string;
	showButtonNewContract?: boolean;
	onClickNovoContract?: () => void;

	textButtonSearch?: string;
	showButtonSearch?: boolean;
	onClickButtonSearch?: () => void;

	textButtonContract?: string;
	showButtonContract?: boolean;
	onClickButtonContract?: () => void;

	textFilterField?: string;
	showFilterField?: boolean;
	filterFieldJson?: TFilterFields[] | undefined;
	onChangeFilterField?: (newText: string) => void;

	showInputStatus?: boolean;
	onChangeStatus?: (text: string) => void;

	showInputOrigem?: boolean;
	onChangeOrigem?: (text: string) => void;

	showInputUF?: boolean;
	onChangeUF?: (text: string) => void;

	showPeriodo?: boolean;

	onChangeDataIni?: (text: string) => void;
	onChangeDataFim?: (text: string) => void;
	isDateStartDefault?: boolean;

	showBusyImobile?: boolean;
	textBusyImobile?: string;
	optionsBusyImobile?: TFilterFields[] | undefined;
	onChangeBusyImobile?: (text: string) => void;

	showTypePeriod?: boolean;
	textTypePeriod?: string;
	optionsTypePeriod?: TFilterFields[] | undefined;
	onChangeTypePeriod?: (text: string) => void;

	textoButtonNew?: string;
	showButtonNew?: boolean;
	onClickNovo?: () => void;

	showInputClientFilter?: boolean;
	onChangeClientFilter?: (text: string) => void;
	filterInitialParams?: ListSearchParams;

	showInputPermissionFilter?: boolean;
	onChangePermissionFilter?: (text: string) => void;

	showInputManagerFilter?: boolean;
	textoButtonManager?: string;
	onChangeManagerFilter?: (text: string) => void;

	showCheckBox?: boolean;
	textoCheckBox?: string;
	onChangeCheckBox?: (change: boolean) => void;

	showCheckBoxSketch?: boolean;
	textoCheckBoxSketch?: string;
	onChangeCheckBoxSketch?: (change: boolean) => void;

	showTransferButton?: boolean;

	showButtonReport?: boolean;
	onClickButtonReport?: () => void;
	textButtonReport?: string;
	onclickTransfer?: () => void;
}

export const Toolbar: React.FC<IToolbarProps> = ({
	mdCheckBox = 3,
	sxCheckBox = {},
	sxFormControlLabel = {},

	textSearchGrid: textSearch = "",
	showInputBusca = false,
	onChangeTextBuscaGrid: onChangeTextBusca,
	aoLimparTextBuscaGrid: aoLimparTextBusca,

	showTitle = false,
	onChangeTitle,
	showStatusContract = false,
	showDateRegister = false,
	onChangeDateRegister,
	showButtonFind = false,
	onClickButtonFind,
	textButtonFind = "Buscar",
	isLoaddingButtonFind = false,
	textoButtonNewContract = "Novo",
	showButtonNewContract = false,
	onClickNovoContract,

	showButtonContract = false,
	onClickButtonContract,
	textButtonContract = "Contratos",

	showInputStatus = true,
	onChangeStatus,

	showInputUF = false,
	onChangeUF,

	showInputClientFilter = true,
	onChangeClientFilter,

	showInputOrigem = true,
	onChangeOrigem,

	showInputPermissionFilter = false,
	onChangePermissionFilter,

	showInputManagerFilter = false,
	textoButtonManager = "Gestor",
	onChangeManagerFilter,

	showPeriodo = true,
	onChangeDataIni,
	onChangeDataFim,
	isDateStartDefault = false,

	showBusyImobile = false,
	textBusyImobile = "Status de imóvel",
	onChangeBusyImobile,

	showTypePeriod = false,
	textTypePeriod = "Tipo período",
	onChangeTypePeriod,

	textoButtonNew = "Novo",
	showButtonNew = true,
	onClickNovo,

	textoCheckBox = "CheckBox ",
	showCheckBox = false,
	onChangeCheckBox,

	showCheckBoxSketch = false,
	textoCheckBoxSketch = "CheckBox Sketch",
	onChangeCheckBoxSketch,

	textButtonSearch = "Pesquisar",
	showButtonSearch = false,
	onClickButtonSearch,

	textFilterField = "Filtrar por",
	showFilterField = false,
	onChangeFilterField,

	textButtonReport = "Relatório",
	showButtonReport = false,
	onClickButtonReport,
	onclickTransfer,

	filterFieldJson = [{ name: "Name", displayName: "Nome" }],

	optionsTypePeriod = [{ name: "createAt", displayName: "Criação" }],

	optionsBusyImobile = [
		{ name: "Todos", displayName: "Todos" },
		{ name: "busy", displayName: "Ocupado" },
		{ name: "notbusy", displayName: "DesOcupado" },
	],

	filterInitialParams,

	showTransferButton = false,
	sxPaper = {},
}) => {
	const dateStartInitial = moment("01/01/2019").format("YYYY-MM-DD");
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState(filterInitialParams?.status ?? "Todos");
	const [UF, setUF] = useState("Todos");
	const [origem, setOrigem] = useState(filterInitialParams?.origem ?? "Todos");
	const [filterField, setFilterField] = useState(filterInitialParams?.tipo ?? "documento");
	const [dataIni, setDataIni] = useState(
		isDateStartDefault ? dateStartInitial : filterInitialParams?.dataIni ?? moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD")
	);
	const [dataFim, setDataFim] = useState(filterInitialParams?.dataFim ?? moment().endOf("month").format("YYYY-MM-DD"));
	const [typePeriod, setTypePeriod] = useState(filterInitialParams?.typePeriod ?? "createdAt");
	const [busyImobile, setBusyImobile] = useState(filterInitialParams?.imovel ?? "Todos");

	const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
	const [selectedId, setSelectedId] = useState<string | undefined>();
	const [busca, setBusca] = useState("");
	const [clienteFilter, setClienteFilter] = useState<string>("");
	const [value, setValue] = useState<string | null>(null);
	const [inputValue, setInputValue] = React.useState("");
	const [inputTextValue, setInputTextValue] = React.useState("");
	const { userClientId, isSuperAdmin, permissionLevel, registerClient } = useProfile();
	const toastHandler = useToast();
	const [opcoesPermission, setOpcoesPermission] = useState<TAutoCompleteOption[]>([]);
	const [selectedIdPermission, setSelectedIdPermission] = useState<string | undefined>();
	const [selectedIdManager, setSelectedIdManager] = useState<string | undefined>();
	const [opcoesManager, setOpcoesManager] = useState<TAutoCompleteOption[]>([]);
	const [nameManager, setNameManager] = useState<string>("");
	const [statusCheckbox, setStatusCheckBox] = useState(filterInitialParams?.lmiSaldo ?? "0");
	const [statusSketch, setStatusSketch] = useState(filterInitialParams?.sketch ?? "0");

	const navigate = useNavigate();

	const handleChangeStatus = (event: SelectChangeEvent) => {
		setStatus(event.target.value as string);
		onChangeStatus?.(event.target.value as string);
	};

	const handleChangeTypePeriod = (event: SelectChangeEvent) => {
		setTypePeriod(event.target.value as string);
		onChangeTypePeriod?.(event.target.value as string);
	};

	const handleChangeBusyImobile = (event: SelectChangeEvent) => {
		setBusyImobile(event.target.value as string);
		onChangeBusyImobile?.(event.target.value as string);
	};

	const handleChangeOrigem = (event: SelectChangeEvent) => {
		setOrigem(event.target.value as string);
		onChangeOrigem?.(event.target.value as string);
	};

	const handleChangeUF = (event: SelectChangeEvent) => {
		setUF(event.target.value as string);
		onChangeUF?.(event.target.value as string);
	};

	const handleChangeFilterField = (event: SelectChangeEvent) => {
		setFilterField(event.target.value as string);
		onChangeFilterField?.(event.target.value as string);
	};
	const handleClienteFilter = (event: SelectChangeEvent) => {
		alert("change handle");
		setClienteFilter(event.target.value as string);
		onChangeClientFilter?.(event.target.value as string);
	};
	const handleChangeTextbusca = (value: string) => {
		setInputTextValue(value);
	};

	const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChangeCheckBox?.(event.target.checked);
	};

	const handleChangeCheckBoxSketch = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChangeCheckBoxSketch?.(event.target.checked);
	};

	useEffect(() => {
		setIsLoading(true);
		clientService
			.getSelect(1, busca?.replace("(Inativo)", "")?.trim(), "", "", "", isSuperAdmin || permissionLevel == 2 ? "" : userClientId)
			.then((result: any) => {
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					const options = result?.data?.map((client: any) => ({
						id: client.id,
						label: client.status == "inactive" ? `${client.name} (Inativo)` : client.name,
					}));
					//if (!isSuperAdmin && options?.length == 1) {
					if ((!isSuperAdmin || permissionLevel == 2) && options?.length == 1) {
						setSelectedId(options[0].id);
					}
					setOpcoes(options);
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					durationMs: 4000,
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	}, [busca]);

	useEffect(() => {
		setIsLoading(true);
		PermissionServices.getUnlimited(isSuperAdmin || permissionLevel == 2 ? 1 : Number(permissionLevel))
			.then((result: any) => {
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					const options = result?.data?.map((permission: IPermission) => ({ id: permission.id, label: permission.description }));
					setOpcoesPermission(options);
				}
			})
			.catch((err: any) => {
				setIsLoading(false);
				const { titleMessage, contentMessage } = returnMessageError(err);

				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			});
	}, []);

	useEffect(() => {
		if (isSuperAdmin || permissionLevel == 2) {
			setIsLoading(true);
			userService
				.getAdminManagers(nameManager)
				.then((result: any) => {
					setIsLoading(false);
					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						const options = result?.data?.map((user: IListagemUsuario) => ({ id: user.id, label: user.name }));
						setOpcoesManager(options);
					}
				})
				.catch((err: any) => {
					setIsLoading(false);
					const { titleMessage, contentMessage } = returnMessageError(err);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		}
	}, [nameManager]);

	const autoCompleteSelectedOption = useMemo(() => {
		if (!selectedId) return null;
		const selectedOption = opcoes.find((opcao) => opcao.id === selectedId);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedId, opcoes]);

	const autoCompleteSelectedPerfimissions = useMemo(() => {
		if (!selectedIdPermission) return null;
		const selectedOption = opcoesPermission.find((opcao) => opcao.id === selectedIdPermission);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedIdPermission, opcoesPermission]);

	const autoCompleteSelectedManager = useMemo(() => {
		if (!selectedIdManager) return null;
		const selectedOption = opcoesManager.find((opcao) => opcao.id === selectedIdManager);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedIdManager, opcoesManager]);

	return (
		<Box gap={1} marginX={1} padding={2} paddingX={3} display="flex" sx={sxPaper} alignItems="space-beetwen" component={Paper}>
			<Grid container item direction="row" spacing={2}>
				<Grid
					container
					item
					direction="row"
					xs={12}
					sm={12}
					md={showButtonNew || showButtonNewContract || showButtonSearch || showButtonReport ? 10 : 12}
					rowGap={2}
				>
					{showInputManagerFilter && (
						<Grid item xs={12} sm={12} md={4} paddingRight={1}>
							<FormControl fullWidth>
								<Autocomplete
									fullWidth
									openText="Abrir"
									closeText="Fechar"
									noOptionsText="Nenhuma opção"
									loadingText="Loading ..."
									disablePortal
									value={autoCompleteSelectedManager}
									options={opcoesManager}
									loading={isLoading}
									size={"small"}
									onInputChange={(_, newValue) => {
										setNameManager(newValue);
									}}
									onChange={(_, value: any) => {
										setSelectedIdManager(value?.id ? value?.id : "");
										onChangeManagerFilter?.(value?.id ? value?.id : ("" as string));
									}}
									renderInput={(params) => <TextField {...params} label={textoButtonManager} name="gestor" />}
								/>
							</FormControl>
						</Grid>
					)}

					{showInputClientFilter && (
						<Grid item xs={12} sm={12} md={4} paddingRight={1}>
							<FormControl fullWidth>
								<Autocomplete
									fullWidth
									openText="Abrir"
									closeText="Fechar"
									noOptionsText="Nenhuma opção"
									loadingText="Loading ..."
									disablePortal
									value={autoCompleteSelectedOption}
									options={opcoes}
									loading={isLoading}
									size={"small"}
									onInputChange={(_, newValue) => {
										setBusca(newValue);
										setInputValue(newValue);
									}}
									onChange={(event, newValue) => {
										setClienteFilter(newValue?.id ? newValue?.id : ("" as string));
										onChangeClientFilter?.(newValue?.id ? newValue?.id : ("" as string));
										setSelectedId(newValue?.id ? newValue?.id : "");
									}}
									renderInput={(params) => <TextField {...params} label="Cliente" name="cliente" />}
								/>
							</FormControl>
						</Grid>
					)}

					{showInputPermissionFilter && (
						<Grid item xs={12} sm={12} md={4} paddingRight={1}>
							<FormControl fullWidth>
								<Autocomplete
									fullWidth
									openText="Abrir"
									closeText="Fechar"
									noOptionsText="Nenhuma opção"
									loadingText="Loading ..."
									disablePortal
									value={autoCompleteSelectedPerfimissions}
									options={opcoesPermission}
									loading={isLoading}
									size={"small"}
									onChange={(_, value: any) => {
										setSelectedIdPermission(value?.id ? value?.id : "");
										onChangePermissionFilter?.(value?.label ? value?.label : ("" as string));
									}}
									renderInput={(params) => <TextField {...params} label="Perfis" name="perfis" />}
								/>
							</FormControl>
						</Grid>
					)}

					{showTitle && (
						<Grid item xs={12} md={4} paddingRight={1}>
							<TextField
								fullWidth
								label="Titulo"
								name="title"
								InputLabelProps={{ shrink: true, required: false }}
								size="small"
								type="text"
								onChange={(e: any) => onChangeTitle?.(e.target.value)}
							></TextField>
						</Grid>
					)}

					{showInputStatus && (
						<Grid item xs={12} sm={12} md={4} paddingRight={1}>
							<FormControl fullWidth>
								<InputLabel id="id_status">Status</InputLabel>
								<Select labelId="label_Status" id="id_status" value={status} size="small" label="Status" onChange={handleChangeStatus}>
									<MenuItem value={"Todos"}>Todos </MenuItem>
									<MenuItem value={"active"}>Ativo</MenuItem>
									<MenuItem value={"inactive"}>Inativo</MenuItem>
									{!showStatusContract && <MenuItem value={"pending"}>Pendente</MenuItem>}
									{showStatusContract && <MenuItem value={"canceled"}>Cancelado</MenuItem>}
									<MenuItem value={"blocked"}>Bloqueado</MenuItem>
									<MenuItem value={"contract_pending"}>Contrato</MenuItem>
									{showStatusContract && <MenuItem value={"sent"}>Enviado</MenuItem>}
								</Select>
							</FormControl>
						</Grid>
					)}

					{showDateRegister && (
						<Grid item xs={12} md={3} paddingRight={1}>
							<TextField
								fullWidth
								label="Data de Cadastro"
								name="dateRegister"
								InputLabelProps={{ shrink: true, required: false }}
								size="small"
								type="date"
								onChange={(e: any) => {
									onChangeDateRegister?.(e.target.value);
								}}
							></TextField>
						</Grid>
					)}

					{showInputOrigem && (
						<Grid item xs={12} sm={12} md={4} paddingRight={1}>
							<FormControl fullWidth>
								<InputLabel id="id_origem">Origem</InputLabel>
								<Select labelId="label_Origem" id="id_origem" value={origem} size="small" label="Origem" onChange={handleChangeOrigem}>
									<MenuItem value={"Todos"}>Todos</MenuItem>
									<MenuItem value={"web_app"}>Web</MenuItem>
									<MenuItem value={"web_service"}>Integração</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}

					{showInputUF && (
						<Grid item xs={12} sm={12} md={4} paddingRight={1}>
							<FormControl fullWidth>
								<InputLabel id="id_UF">UF</InputLabel>
								<Select labelId="label_UF" id="id_UF" value={UF} size="small" label="UF" onChange={handleChangeUF}>
									{RangeStates.map(({ value, label }, key) => (
										<MenuItem value={value}>{label}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}

					{showBusyImobile && (
						<Grid item xs={12} md={3} paddingRight={1}>
							<FormControl fullWidth>
								<InputLabel id="id_type_period"> {textBusyImobile}</InputLabel>
								<Select
									labelId="busyImobile"
									id="idBusyImobile"
									value={busyImobile}
									size="small"
									label={textBusyImobile}
									name="busImobile"
									disabled={isLoading}
									onChange={handleChangeBusyImobile}
								>
									{optionsBusyImobile?.map((element, index) => (
										<MenuItem key={index} value={element.name}>
											{element.displayName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}

					{showTypePeriod && (
						<Grid item xs={12} md={3} paddingRight={1}>
							<FormControl fullWidth>
								<InputLabel id="id_type_period"> {textTypePeriod}</InputLabel>
								<Select
									labelId="typePeriod"
									id="id_type_period"
									value={typePeriod}
									size="small"
									label={textTypePeriod}
									name="typePeriod"
									disabled={isLoading}
									onChange={handleChangeTypePeriod}
								>
									{optionsTypePeriod?.map((element, index) => (
										<MenuItem key={index} value={element.name}>
											{element.displayName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}

					{showPeriodo && (
						<>
							<Grid item xs={12} sm={12} md={3} paddingRight={1}>
								<TextField
									fullWidth
									label="Data Inicial"
									name="datainicial"
									size="small"
									type="date"
									value={dataIni}
									onChange={(e) => (setDataIni(moment(e.target.value).format("YYYY-MM-DD")), onChangeDataIni?.(e.target.value))}
								></TextField>
							</Grid>
							<Grid item xs={12} sm={12} md={3} paddingRight={1}>
								<TextField
									fullWidth
									label="Data Final"
									name="datafinal"
									size="small"
									type="date"
									value={dataFim}
									onChange={(e) => (setDataFim(moment(e.target.value).format("YYYY-MM-DD")), onChangeDataFim?.(e.target.value))}
								></TextField>
							</Grid>
						</>
					)}

					{showTransferButton &&
						registerClient.walletTransfer &&
						checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.WalletTransfer) && (
							<Grid item xs={12} md={1.5} paddingRight={1}>
								<Button
									sx={{
										height: "40px",
										fontSize: "14px",
										margin: 0,
										alignSelf: "end",
										backgroundColor: "white",
										border: "1px solid darkgray",
										fontWeight: "bold",
										"&.MuiButtonBase-root:hover": {
											border: "1px solid darkgray",
											backgroundColor: "white",
										},
									}}
									color="primary"
									disableElevation
									variant="outlined"
									onClick={onclickTransfer}
									startIcon={<Icon sx={{ marginRight: "1px" }}>transform</Icon>}
								>
									Portabilidade
								</Button>
							</Grid>
						)}

					<Grid container item direction="row">
						{showFilterField && (
							<Grid item xs={12} sm={12} md={3} paddingRight={1}>
								<FormControl fullWidth>
									<InputLabel id="filterField">{textFilterField} </InputLabel>
									<Select
										labelId="labelFilter"
										id="filterField"
										value={filterField}
										size="small"
										label={textFilterField}
										onChange={handleChangeFilterField}
									>
										{filterFieldJson?.map((element, index) => (
											<MenuItem key={index} value={element.name}>
												{element.displayName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}

						{showInputBusca && (
							<Grid item xs={12} md={4} marginRight={3}>
								<Box display="flex" justifyContent="start">
									<TextField
										fullWidth
										size="small"
										value={inputTextValue}
										placeholder={Environment.INPUT_BUSCA}
										onChange={(e) => {
											setInputTextValue(e.target.value);
											onChangeTextBusca?.(e.target.value);
										}}
										InputProps={{
											startAdornment: <></>,
											endAdornment: (
												<>
													{" "}
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															edge="end"
															sx={{ color: "#dbd5d5" }}
															onClick={() => {
																setInputTextValue(""), onChangeTextBusca?.("");
															}}
														>
															<Clear />
														</IconButton>
													</InputAdornment>
													<InputAdornment position="end" sx={{ color: "green" }}>
														<Tooltip title="Clique para Pesquisar">
															<IconButton
																aria-label="toggle password visibility"
																onClick={onClickButtonSearch}
																// onMouseDown={handleMouseDownPassword}
																disableRipple
																disableFocusRipple
																edge="end"
																// color="primary"
																sx={{ color: "white", backgroundColor: "#7924c7", borderRadius: 0 }}
															>
																<Search />
															</IconButton>
														</Tooltip>
													</InputAdornment>
												</>
											),
										}}
									/>
								</Box>
							</Grid>
						)}

						{showCheckBox && (
							<Grid item xs={12} md={mdCheckBox} sx={sxCheckBox}>
								<FormControlLabel
									control={
										<Switch
											onChange={handleChangeCheckBox}
											name="nameCheckBox"
											color="secondary"
											defaultChecked={statusCheckbox && statusCheckbox == "1" ? true : false}
											disabled={false}
										/>
									}
									label={textoCheckBox}
									sx={sxFormControlLabel}
								/>
							</Grid>
						)}

						{showCheckBoxSketch && (
							<Grid item xs={12} md={mdCheckBox} sx={sxCheckBox}>
								<FormControlLabel
									control={
										<Switch
											onChange={handleChangeCheckBoxSketch}
											name="nameCheckBoxSketch"
											color="secondary"
											defaultChecked={statusSketch && statusSketch == "1" ? true : false}
											disabled={false}
										/>
									}
									label={textoCheckBoxSketch}
									sx={sxFormControlLabel}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>

				{(showButtonNew || showButtonSearch || showButtonNewContract || showButtonReport) && (
					<Grid container item display="flex" direction="column" xs={12} md={2} gap={2}>
						{showButtonNew && (
							<Box display="flex" justifyContent="end">
								<Button color="primary" disableElevation variant="contained" onClick={onClickNovo} startIcon={<Icon>add</Icon>}>
									{textoButtonNew}
								</Button>
							</Box>
						)}

						{showButtonSearch && (
							<Box display="flex" justifyContent="end">
								<Button color="primary" disableElevation variant="contained" startIcon={<FilterAltIcon />}>
									{textButtonSearch}
								</Button>
							</Box>
						)}

						{showButtonContract && (
							<Grid item xs={12} md={2} textAlign={"end"}>
								<Button
									sx={{
										border: "1px solid darkgray",
										marginTop: "3px",
										height: "40px",
										backgroundColor: "white",
										"&.MuiButtonBase-root:hover": {
											border: "1px solid darkgray",
											backgroundColor: "white",
										},
									}}
									color="primary"
									disableElevation
									variant="outlined"
									onClick={onClickButtonContract}
									startIcon={<Icon>auto_awesome_motion_sharp_icon</Icon>}
								>
									{textButtonContract}
								</Button>
							</Grid>
						)}
						{showButtonReport && (
							<Box display="flex" justifyContent="end">
								<Button color="primary" onClick={onClickButtonReport} disableElevation variant="outlined" startIcon={<PrintOutlined />}>
									{textButtonReport}
								</Button>
							</Box>
						)}

						{showButtonFind && (
							<Grid item xs={12} md={3} textAlign={isLoaddingButtonFind ? "center" : "end"}>
								{isLoaddingButtonFind ? (
									<CircularProgress variant="indeterminate" />
								) : (
									<Button
										sx={{
											border: "1px solid darkgray",
											marginTop: "3px",
											height: "40px",
											"&.MuiButtonBase-root:hover": {
												border: "1px solid darkgray",
											},
										}}
										fullWidth
										color="primary"
										disableElevation
										variant="contained"
										onClick={onClickButtonFind}
										startIcon={<Icon>search</Icon>}
									>
										{textButtonFind}
									</Button>
								)}
							</Grid>
						)}
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
