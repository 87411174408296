import { useEffect, useMemo, useState } from "react";
import {
	Icon,
	IconButton,
	LinearProgress,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { IProposalList, ProposalService } from "../../../shared/services/api/proposal/ProposalService";
import { Loading, Toolbar, ToolbarGridProposal } from "../../../shared/components";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { useDebounce } from "../../../shared/hooks";
import { Environment } from "../../../shared/environment";
import moment, { months } from "moment";
import "moment/locale/pt-br";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { differenceInDays } from "date-fns";
import { ListSearchParams } from "../../analysis/types/list-search-params.type";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useProfile } from "../../../context/ProfileContext";
import { useToast } from "../../../shared/hooks";
import { ToastContent } from "../../../shared/components";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { TFilterFields } from "../../../shared/types/filterfields.type";
import { globalTranslate } from "../../../shared/utils";
import { ModalPortable } from "../../../shared/components/ModalPortable/ModalPortable";

function colorBackGround(status: string) {
	if (status === "renovated") {
		return "#a9e9eb";
	}
	if (status === "suspended") {
		return "#EEC5C5";
	}
	if (status === "active") {
		return "#C5EEC9";
	}
	if (status === "expired") {
		return "#F4ECC0";
	}
	if (status === "canceled") {
		return "#EEC5C5";
	}

	return "#959595";
}
function colorIcon(status: string) {
	if (status === "expired") {
		return "#e2d73a";
	}
	if (status === "active") {
		return "#5ebe76";
	}
	if (status === "canceled") {
		return "#eb3232";
	}
	if (status === "suspended") {
		return "#eb3232";
	}
	if (status === "renovated") {
		return "#3761eb";
	}
	return "#202020";
}

function colorText(status: string) {
	if (status === "expired") {
		return "#e2c93a";
	}
	if (status === "active") {
		return "#5ebe76";
	}
	if (status === "canceled") {
		return "#eb3232";
	}
	if (status === "suspended") {
		return "#eb3232";
	}
	if (status === "renovated") {
		return "#3761eb";
	}

	return "#202020";
}

function translate(traduzir: String) {
	if (traduzir === "canceled") {
		return "Cancelada";
	}
	if (traduzir === "pending") {
		return "Faturada";
	}
	if (traduzir === "open") {
		return "Aberto";
	}
	if (traduzir === "paid") {
		return "Pago";
	}

	if (traduzir === "active") {
		return "Ativa";
	}
	if (traduzir === "expired") {
		return "Expirada";
	}
	if (traduzir === "canceled") {
		return "Cancelada";
	}
	if (traduzir === "renovated") {
		return "Renovada";
	}
	if (traduzir === "suspended") {
		return "Suspensa";
	}

	return traduzir;
}

function terminogarantia(datafim: string): string {
	var now = moment(new Date()); //todays date
	var end = moment(datafim.substring(0, 10) + " 23:59:59"); // another date
	var duration = moment.duration(now.diff(end));
	var days = duration.asDays();
	const retStatus = days > 0 ? " Expira em " : " expirada a ";

	return retStatus + Math.abs(days).toFixed(0).toString() + " dias ";
}

const maskReal = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/(\d{1,2})$/, ",$1")
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const ProposalListPresentation = () => {
	const filterFieldsTypePeriod: TFilterFields[] = [
		{ name: "contratacao", displayName: "Vigência" },
		// {name:'contrato'    , displayName:'Contrato'},
		{ name: "renovacao", displayName: "Renovação" },
		{ name: "criacao", displayName: "Criação" },
		{ name: 'apuracao', displayName: 'Apuração' },
	];
	const filterProposal: TFilterFields[] = [
		{ name: "documento", displayName: "Documento do locatário" },
		{ name: "nome", displayName: "Nome do locatário" },
		{ name: "documentoLocator", displayName: "Documento do locador" },
		{ name: "nomeLocator", displayName: "Nome do locador" },
	];

	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const [rows, setRows] = useState<IProposalList[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.View);
	}, []);
	const toastHandler = useToast();
	const { isSuperAdmin, permissionLevel } = useProfile();
	const [pesquisar, setPesquisar] = useState(true);
	const [transfers, setTransfers] = useState(false);

	const filterInitialParams: ListSearchParams = useMemo(() => {
		return {
			status: "Todos",
			origem: "Todos",
			tipo: "documento",
			dataIni: moment().startOf("month").subtract(24, "months").format("YYYY-MM-DD"),
			dataFim: moment().endOf("month").format("YYYY-MM-DD"),
			typePeriod: "contratacao",
		};
	}, []);

	const searchParamsInitial = () => {
		setSearchParams(
			{
				...searchParams,
				status: filterInitialParams.status,
				origem: filterInitialParams.origem,
				typePeriod: filterInitialParams.typePeriod,
				tipo: filterInitialParams.tipo,
				dataini: moment("01/01/2019").format("YYYY-MM-DD"),
				datafim: filterInitialParams.dataFim,
			},
			{ replace: true }
		);
	};

	useEffect(() => {
		searchParamsInitial();
	}, []);

	const busca = useMemo(() => {
		return searchParams.get("busca") || "";
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const tipoProposal = useMemo(() => {
		return searchParams.get("tipo") || "";
	}, [searchParams]);

	const statusProposal = useMemo(() => {
		return searchParams.get("status") || "Todos";
	}, [searchParams]);

	const origemProposal = useMemo(() => {
		return searchParams.get("origem") || "";
	}, [searchParams]);

	const dataIniProposal = useMemo(() => {
		return searchParams.get("dataini") || ""; //moment().startOf('month').subtract(1, 'months').format("YYYY-MM-DD"));
	}, [searchParams]);

	const dataFimProposal = useMemo(() => {
		return searchParams.get("datafim") || ""; ///moment().endOf('month').format("YYYY-MM-DD"));
	}, [searchParams]);

	const clientFilter = useMemo(() => {
		return searchParams.get("clientFilter") || "";
	}, [searchParams]);

	const managerId = useMemo(() => {
		return searchParams.get("managerId") || "";
	}, [searchParams]);

	const typeField = useMemo(() => {
		return searchParams.get("tipo") || "documento";
	}, [searchParams]);

	const typePeriod = useMemo(() => {
		return searchParams.get("typePeriod") || "contratacao";
	}, [searchParams]);

	const tombamento = useMemo(() => {
		return searchParams.get("tombamento") || "0";
	}, [searchParams]);

	const handleBusca = () => {
		setSearchParams(
			{
				busca,
				pagina: String(1),
				tipo: tipoProposal,
				status: statusProposal,
				origem: origemProposal,
				dataini: dataIniProposal,
				datafim: dataFimProposal,
				clientFilter: clientFilter,
				managerId,
				typePeriod,
				tombamento,
			},
			{ replace: true }
		);
		setPesquisar(!pesquisar);
	};

	useEffect(() => {
		if (hasViewPermission) {
			setIsLoading(true);
			debounce(() => {
				ProposalService.getAll(
					pagina,
					busca,
					tipoProposal,
					origemProposal,
					statusProposal,
					dataIniProposal,
					dataFimProposal,
					clientFilter,
					managerId,
					typePeriod,
					tombamento
				)
					.then((result) => {
						setIsLoading(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							setTotalCount(result.totalCount);
							setRows(result.data);
						}
					})
					.catch((err: any) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	}, [pagina, statusProposal, pesquisar, tombamento]);

	const URL = window.location.protocol + "//" + window.location.host;
	const handleTransfer = () => {
		setTransfers(!transfers);
	};

	return (
		<LayoutBaseDePagina
			titulo="Garantias"
			subtitulo="Listagem de garantias"
			barraDeFerramentas={
				<Toolbar
					filterInitialParams={filterInitialParams}
					textoButtonNew="nova"
					textButtonSearch="Filtrar"
					textoCheckBox="Tombamento"
					showButtonSearch={false}
					showInputStatus={false}
					showInputClientFilter={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Filter)}
					showInputOrigem={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Filter)}
					showPeriodo={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Filter)}
					isDateStartDefault={true}
					showInputBusca={true}
					showCheckBox={isSuperAdmin || permissionLevel == 2 ? true : false}
					showButtonNew={checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Add)}
					showInputManagerFilter={isSuperAdmin || permissionLevel == 2}
					showFilterField={true}
					showTypePeriod={true}
					optionsTypePeriod={filterFieldsTypePeriod}
					filterFieldJson={filterProposal}
					mdCheckBox={3}
					sxCheckBox={{ textAlign: "start" }}
					sxFormControlLabel={{ marginRight: 0 }}
					onClickNovo={() => navigate("/analises/detalhe/novo")}
					onClickButtonSearch={handleBusca}
					onChangeTextBuscaGrid={(texto) => {
						setSearchParams(
							{
								busca: texto?.replace("(Inativo)", "")?.trim(),
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: origemProposal,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: clientFilter,
								managerId,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						);
					}}
					showTransferButton={true}
					onclickTransfer={handleTransfer}
					onChangeStatus={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: retorno,
								origem: origemProposal,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: clientFilter,
								managerId,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						)
					}
					onChangeOrigem={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: retorno,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: clientFilter,
								managerId,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						)
					}
					onChangeDataIni={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: origemProposal,
								dataini: retorno,
								datafim: dataFimProposal ? dataFimProposal : moment().endOf("month").format("YYYY-MM-DD"),
								clientFilter: clientFilter,
								managerId,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						)
					}
					onChangeDataFim={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: origemProposal,
								dataini: dataIniProposal ? dataIniProposal : moment().startOf("month").format("YYYY-MM-DD"),
								datafim: retorno,
								clientFilter: clientFilter,
								managerId,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						)
					}
					onChangeClientFilter={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: origemProposal,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: retorno,
								managerId,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						)
					}
					onChangeManagerFilter={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: origemProposal,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: clientFilter,
								managerId: retorno,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						);
					}}
					onChangeTypePeriod={(retorno: string) => {
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: origemProposal,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: clientFilter,
								managerId,
								typePeriod: retorno,
								tombamento,
							},
							{ replace: true }
						);
					}}
					onChangeCheckBox={(retorno: boolean) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: tipoProposal,
								status: statusProposal,
								origem: origemProposal,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: clientFilter,
								managerId,
								typePeriod,
								tombamento: `${retorno ? "1" : "0"}`,
							},
							{ replace: true }
						)
					}
					onChangeFilterField={(retorno: string) =>
						setSearchParams(
							{
								busca,
								pagina: String(1),
								tipo: retorno,
								status: statusProposal,
								origem: origemProposal,
								dataini: dataIniProposal,
								datafim: dataFimProposal,
								clientFilter: clientFilter,
								managerId,
								typePeriod,
								tombamento,
							},
							{ replace: true }
						)
					}
				/>
			}
		>
			<ProfileGuard requiredRole={PermissionRolesEnum.WarrantiesOptions} requiredAction={PermissionActionsEnum.View}>
				<TableContainer component={Paper} variant="outlined" sx={{ marginTop: 1, width: "auto", padding: "20px", ml: 1, overflow: "scroll" }}>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell colSpan={8} sx={{ padding: "0px" }}>
									<ToolbarGridProposal
										textSearchGrid={busca}
										onChangeTextSearchGrid={(text) =>
											setSearchParams(
												{
													busca: text,
													pagina: "1",
													tipo: tipoProposal,
													status: "",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										showInputSearchGrid={false}
										onClickAll={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "Todos",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										onClickActive={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "active",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										onClickEmitidas={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "emited",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										onClickExpires={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "expires",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										onClickExpired={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "expired",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										onClickCanceled={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "canceled",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										onClickSuspended={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "suspended",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										onClickRenovated={() =>
											setSearchParams(
												{
													busca,
													pagina: String(1),
													tipo: tipoProposal,
													status: "renovated",
													origem: origemProposal,
													dataini: dataIniProposal,
													datafim: dataFimProposal,
													clientFilter: clientFilter,
													managerId,
													typePeriod,
													tombamento,
												},
												{ replace: true }
											)
										}
										selectAll={statusProposal === "Todos"}
										selectAtive={statusProposal === "active"}
										selectEmitidas={statusProposal === "emited"}
										selectRenovated={statusProposal === "renovated"}
										selectExpires={statusProposal === "expires"}
										selectExpired={statusProposal === "expired"}
										selectSuspended={statusProposal === "suspended"}
										selectCanceled={statusProposal === "canceled"}
									/>
								</TableCell>
							</TableRow>

							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Parceiro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Endereço</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Valor Parcela </TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Status </TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Cadastro</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> Origem</TableCell>
								<TableCell sx={{ color: "#7e7777b5" }}> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading &&
								rows.map((row) => (
									<TableRow key={row.id} hover>
										<TableCell sx={{ fontWeight: "normal" }}>
											{row.name}
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.document.length > 11
													? row.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5")
													: row.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
												{row.client?.name}
												{row.client?.status == "inactive" && " (Inativo)"}
											</Typography>
										</TableCell>

										<TableCell sx={{ color: "#959595", fontWeight: "normal" }}>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.addressStreet != null && row.addressStreet != ""
													? `${
															row.addressComplement != null && row.addressComplement != ""
																? `${row.addressStreet} , ${row.addressNumber} - ${row.addressComplement} - ${row.addressDistrict}`
																: `${row.addressStreet} , ${row.addressNumber} - ${row.addressDistrict}`
													  }`
													: "Endereço não cadastrado"}
											</Typography>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.addressCity != null && row.addressCity != "" ? `${row.addressCity} - ${row.addressState}` : ""}
											</Typography>
											<Typography variant="caption" fontWeight={"bold"} color={"GrayText"}>
												{row.propertyFinally != undefined ? globalTranslate(row.propertyFinally) : ""}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
												{/* {maskReal(`${row?.assuranceMonthAmount}`)} */}
												{parseFloat(`${row?.assuranceMonthAmount}`).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton
												size="small"
												sx={{
													background: colorBackGround(
														row.status == "active" &&
															row.renovatedId &&
															differenceInDays(
																new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:01" : "2021-01-01 03:00:01"),
																new Date()
															) > 0
															? "renovated"
															: row.status
													),
													borderRadius: "18px",
													paddingX: "15px",
													paddingY: "1px",
													marginBottom: "5px",
												}}
											>
												<Icon
													sx={{
														color: colorIcon(
															row.status == "active" &&
																row.renovatedId &&
																differenceInDays(
																	new Date(
																		row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:01" : "2021-01-01 03:00:01"
																	),
																	new Date()
																) > 0
																? "renovated"
																: row.status
														),
														fontWeight: "normal",
													}}
												>
													{" "}
													noise_control_off_sharp_icon
												</Icon>
												<Typography
													variant="subtitle2"
													marginLeft={1}
													sx={{
														color: colorText(
															row.status == "active" &&
																row.renovatedId &&
																differenceInDays(
																	new Date(
																		row.termBegin ? row.termBegin.substring(0, 10) + " 3:00:01" : "2021-01-01 03:00:01"
																	),
																	new Date()
																) > 0
																? "renovated"
																: row.status
														),
														fontWeight: "normal",
													}}
												>
													{row.status == "active" &&
													row.renovatedId &&
													differenceInDays(
														new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 3:00:01" : "2021-01-01 03:00:01"),
														new Date()
													) > 0
														? ` Em Renovação `
														: translate(row.status)}
												</Typography>
											</IconButton>
											<IconButton size="small" sx={{ borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
												{row.renovatedId ? (
													<Tooltip title="Garantia renovada" placement="top-start">
														<Icon sx={{ color: colorText("renovated"), fontWeight: "normal" }}> refresh_outlined_icon</Icon>
													</Tooltip>
												) : (
													""
												)}
											</IconButton>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{moment(row.termBegin).format("L")} a {moment(row.expiresAt).format("L")}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{moment(
													row.createdAt
														? row.createdAt.substring(0, 10) + " 03:00:01"
														: row.updatedAt
														? row.updatedAt.substring(0, 10) + " 03:00:01"
														: "2021-01-01 03:00:01"
												).format("L")}
											</Typography>
											<Typography variant="subtitle2" color={"#f80404"} fontWeight={"normal"}>
												{` ${
													row.renovateAt
														? " Renovada em: " + moment(row.renovateAt).format("L")
														: row.renovatedId &&
														  differenceInDays(
																new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:01" : "2021-01-01 03:00:01"),
																new Date()
														  ) > 0
														? " Em Renovação"
														: differenceInDays(
																new Date(row.expiresAt ? row.expiresAt.substring(0, 10) + " 00:00:01" : "2021-01-01"),
																new Date()
														  ) >= 0 &&
														  differenceInDays(
																new Date(row.expiresAt ? row.expiresAt.substring(0, 10) + " 00:00:01" : "2021-01-01"),
																new Date()
														  ) <= 60
														? differenceInDays(
																new Date(row.expiresAt ? row.expiresAt.substring(0, 10) + " 00:00:01" : "2021-01-01"),
																new Date()
														  ) == 0
															? "Atenção expira Hoje"
															: " Restam " +
															  differenceInDays(
																	new Date(row.expiresAt ? row.expiresAt.substring(0, 10) + " 00:00:01" : "2021-01-01"),
																	new Date()
															  ) +
															  " dia(s)"
														: ""
												} 
                    `}
											</Typography>
										</TableCell>
										<TableCell sx={{ textAlign: "start" }}>
											<IconButton size="small" sx={{ borderRadius: "18px", paddingX: "15px", paddingY: "1px", marginBottom: "5px" }}>
												{row.createdFrom == "web_app" ? (
													<Tooltip title="Aplicação" placement="top-start">
														<Icon sx={{ color: "#959595", fontWeight: "normal" }}> computer</Icon>
													</Tooltip>
												) : (
													<Tooltip title="Integração" placement="top-start">
														<Icon sx={{ color: "#959595", fontWeight: "normal" }}> cloud</Icon>
													</Tooltip>
												)}
											</IconButton>
										</TableCell>
										<TableCell sx={{ textAlign: "center" }}>
											<Tooltip title="Clique aqui para abrir a garantia" placement="top-start">
												<IconButton
													size="small"
													onClick={() => window.open(`${URL}/garantias/visualizar/${row.id}`)}
													sx={{ borderRadius: "18px" }}
												>
													<RemoveRedEyeOutlinedIcon sx={{ color: "#0170B3", fontWeight: "normal" }} />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
						{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}
						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}
							{totalCount > 0 && (
								<TableRow>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCount / Environment.LIMITE_LINHAS_GREAT)}
											onChange={(_, newPage) =>
												setSearchParams(
													{
														busca,
														pagina: newPage.toString(),
														status: statusProposal,
														tipo: tipoProposal,
														origem: origemProposal,
														dataini: dataIniProposal,
														datafim: dataFimProposal,
														clientFilter: clientFilter,
														managerId,
														typePeriod,
														tombamento,
													},
													{ replace: true }
												)
											}
										/>
									</TableCell>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Environment.LIMITE_LINHAS_GREAT - (Environment.LIMITE_LINHAS_GREAT - 1)}
											{" - "}
											{Environment.LIMITE_LINHAS_GREAT * (pagina - 1) + Environment.LIMITE_LINHAS_GREAT * pagina < totalCount
												? Environment.LIMITE_LINHAS_GREAT * pagina
												: totalCount}{" "}
											de
											<Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
												{" "}
												{totalCount}{" "}
											</Typography>
											registro(s)
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table>
				</TableContainer>
		
    
    	</ProfileGuard>
			{transfers && (
				<ModalPortable
					isOpenModal={transfers}
					iscloseModal={handleTransfer}
					enableBackdropClose={true}
					overrideWidthValue={"80%"}
					overrideHeightValue={"80%"}
					showButtonConfirm={true}
					onclickConfirm={handleTransfer}
				/>
			)}
		</LayoutBaseDePagina>
	);
};
