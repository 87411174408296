import { Box, DialogContent, DialogTitle, Icon, IconButton, Modal, Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { TableReports } from "../TableReports/TableReports";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";

interface IModalReportProps {
	isOpenModal: boolean;
	onClose: () => void;
	variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;
	enableBackdropClose?: boolean;
	overrideWidthValue?: string;
	overrideHeightValue?: string;
	stylePropert?: React.CSSProperties;
	maxWidth?: string;
	maxHeight?: string;
	width?: string;
	onclickConfirm?: () => void;
}

export const ModalReport: React.FC<IModalReportProps> = ({
	isOpenModal,
	onClose,
	enableBackdropClose,
	maxWidth,
	maxHeight,
	width,
	overrideWidthValue,
	overrideHeightValue,
	variant = "h4",
	onclickConfirm,
}) => {
	const style = {
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: width ? width : "70%",
		height: "auto",
		maxWidth: maxWidth ? maxWidth : "60%",
		maxHeight: maxHeight ? maxHeight : "80%",
		bgcolor: "background.paper",
		border: "1px solid #605e5e",
		boxShadow: 24,
		//overflow: "auto",
		p: 3,
		borderRadius: "6px",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#FFF",
	};

	return (
		<Modal
			open={isOpenModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			onClose={enableBackdropClose ? onClose : () => {}}
		>
			<Box
				sx={{
					...style,
					width: overrideWidthValue ? overrideWidthValue : style.width,
					height: overrideHeightValue ? overrideHeightValue : style.height,
				}}
			>
				<DialogTitle style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 10 }}>
						<Typography id="modal-modal-title" variant={variant} sx={{ fontWeight: 300 }} color={"primary"}>
							Relatórios Solicitados
						</Typography>
						<IconButton color="primary" onClick={onclickConfirm} sx={{ top: 20, right: 20, position: "absolute" }}>
							<Icon sx={{ fontSize: 40 }}> cancel_Outlined_Icon</Icon>
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>					
					<TableReports actionDownload={() => {}} actionView={() => {}} isloading={false} showDownload={true} showView={false} />
				</DialogContent>
			</Box>
		</Modal>
	);
};
